import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Injector,
  OnDestroy,
  Optional,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';

import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { LayoutService } from '@modules/layout/services';
import { PsModalMessages } from '@modules/people-selector/constants';

import {
  MODAL_OVERLAY_DATA,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { AppRuntime, AppRuntimeInterface } from '@shared/services/app-runtime';
import { MediaBreakpoints, MediaService } from '@shared/services/media';

import {
  PeopleSelectorFormService,
  PeopleSelectorService,
} from '../../services';
import {
  PeopleSelectorModalResponse,
  PeopleSelectorPageData,
  PsModalData,
} from '../../types';

@Component({
  selector: 'mg-ps-modal',
  templateUrl: './ps-modal.component.html',
  styleUrls: ['./ps-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PsModalComponent implements OnDestroy, AfterViewInit {
  @ViewChild('overlayElement', { static: false, read: ElementRef })
  private _overlayElement: ElementRef;

  /** Constants */
  public readonly MSG = PsModalMessages;

  /** General Observables */
  private readonly _destroyedSubj = new ReplaySubject<void>(1);

  /** Is Loading */
  private readonly _isLoadingSubj = new BehaviorSubject(false);
  public readonly isLoading$ = this._isLoadingSubj.asObservable();

  /** Show Quick Actions */
  public readonly showQuickActions$ = this.media.breakpoint$.pipe(
    takeUntil(this._destroyedSubj),
    map(bp => {
      const breakpoints: MediaBreakpoints[] = [
        'medium',
        'medium-large',
        'large',
        'xlarge',
      ];
      return breakpoints.includes(bp) ? true : false;
    }),
  );

  /** Component Constructor */
  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    public dialogData: PsModalData,
    @Optional() public form: PeopleSelectorFormService,
    private _modalRef: ModalOverlayRef<
      PeopleSelectorModalResponse,
      PsModalData
    >,
    public media: MediaService,
    private _injector: Injector,
    private _router: Router,
    private _peopleSelector: PeopleSelectorService,
    private _cdr: ChangeDetectorRef,
    public layoutService: LayoutService,
    private _location: Location,
    @Inject(AppRuntimeInterface) private _runtime: AppRuntime,
  ) {
    this._injectState();
    this._setFormOptions();
    this.form.onInit?.();
    this._closeModalSubscription();
  }

  ngAfterViewInit(): void {
    const previewElement = this._overlayElement.nativeElement.children[0];
    previewElement.style.height = window.innerHeight + 'px';
  }

  ngOnDestroy(): void {
    this.form.cleanup(true);
    this._destroyedSubj.next();
    this._destroyedSubj.complete();
    this._isLoadingSubj.complete();
    this.form.onDestroy?.();
  }

  public async changeActivePage(page: string) {
    await this.form.cleanup();
    this._cdr.markForCheck();
    this.form.pages.setActive(page);
  }

  public get canDownloadExport(): boolean {
    return !this._runtime.isNativeApp();
  }

  private _injectState() {
    const { injectionToken } = this.dialogData;
    if (injectionToken) {
      const injectedState =
        this._injector.get<PeopleSelectorFormService>(injectionToken);
      if (injectedState) this.form = injectedState;
      else throw new Error('No state injected');
    } else throw new Error('No injection token provided');
  }

  private _setFormOptions() {
    this.form.pages.setActive(this.dialogData.pagePath);
    const pageData =
      (this._router.getCurrentNavigation().extras
        .state as PeopleSelectorPageData & { data?: any }) ||
      (this._peopleSelector.getPageDataFromSessionStorage(
        this.dialogData.formPath,
        this.dialogData.pagePath,
      ) as PeopleSelectorPageData & { data?: any });
    this.form.setTitle(pageData.title);
    if (pageData?.data) this.form.setData(pageData.data);
    if (pageData.isReadOnly) this.form.setIsReadOnly(pageData.isReadOnly);
    if (this.dialogData?.preSelection)
      this.form.selection.select(this.dialogData.preSelection);
    if (pageData.pagesBlacklist) {
      this.form.setPagesBlacklist(pageData.pagesBlacklist);
    }
  }

  private _closeModalSubscription() {
    return this._peopleSelector
      .eventWhen('close-modal')
      .pipe(takeUntil(this._destroyedSubj))
      .subscribe(() => {
        this._modalRef.close(ModalOverlayServiceCloseEventType.SUBMIT);
      });
  }

  public async back() {
    if (this.form.updated) {
      await this._peopleSelector.close();
    } else this._location.back();
  }
}
