import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { Observable, ReplaySubject } from 'rxjs';

import { IconVariant } from 'src/app/icon';
import { MgIcons } from 'src/app/icon/mg-icons.types';

import { MingaAppColor } from '@shared/constants';
import { MediaService } from '@shared/services/media';

import {
  GenericButtonIconSet,
  GenericButtonSize,
  GenericButtonStyle,
} from './types';

@Component({
  selector: 'mg-btn',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericButtonComponent implements OnInit, OnDestroy {
  @ViewChild('button') button: ElementRef;

  /** General Subjects */
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  /** Functionality Availability */
  private _touchEnabled = window.matchMedia('(pointer: coarse)').matches;

  /** Inputs */
  @Input() public variant: GenericButtonStyle = 'filled';
  @Input() public size: GenericButtonSize = 'medium';
  @Input() public disabled = false;
  @Input() public wide = false;
  @Input() public loading = false;

  /**
   * Icon will display to the left of the text. When you specify the icon, you
   * might also have to specify the iconSet. For example, "mg-export" is an
   * icon that is only available in the "minga" iconSet.
   */
  @Input() public icon: MgIcons | string; // TODO this sucks we dont get autocomplete need to figure out a better way

  /**
   * custom image url (left icon)
   */
  @Input() public iconSvg: string;
  @Input() public iconColor: MingaAppColor;
  @Input() public iconVariant: IconVariant;
  @Input() public iconRight: MgIcons | string; // TODO this sucks we dont get autocomplete need to figure out a better way
  @Input() public iconColorRight: MingaAppColor;
  @Input() public iconVariantRight: IconVariant;
  @Input() public iconSet: GenericButtonIconSet = 'material';
  @Input() public longPressedDelay = 500;
  @Input() public canGrow = true;
  @Input() public attrType: 'button' | 'submit' = 'button';
  @Input() public attrFormId = '';
  @Input() public tooltip: string;
  @Input() public href: string; // if supplied wraps button in an anchor tag
  @Input() public target: '_blank' | '_self' | '_parent' | '_top' = '_self';
  @Input() public tabIndex = 0;
  @Input() public responsive = false;
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;
  /** Set Focus Event */
  @Input() setFocus: Observable<any>;
  private _setFocusEventSub;
  @Input() active = false;

  /** Outputs */
  @Output() pressed = new EventEmitter<void>();
  @Output() longPressed = new EventEmitter<void>();
  @Output() pressedIn = new EventEmitter<void>();
  @Output() pressedOut = new EventEmitter<void>();

  /** Computed Classes */
  get classes() {
    return {
      [this.variant]: true,
      [this.size]: true,
      active: this.active,
      disabled: this.disabled,
      wide: this.wide,
      loading: this.loading,
      'mobile-wide': this.canGrow && !this.wide,
    };
  }

  @HostBinding('class.responsive') get addClass() {
    return this.responsive;
  }

  /** Click Event Binding */
  @HostListener('click') onClick() {
    if (this.disabled || this.loading) return;
    this.pressed.emit();
    this._blur();
  }

  /** Component Constructor */
  constructor(public media: MediaService) {}

  ngOnInit(): void {
    this._setFocusEventSub = this.setFocus?.subscribe(() => this._focusInput());
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._setFocusEventSub?.unsubscribe();
  }

  private _blur() {
    this.button.nativeElement.blur();
  }

  private _focusInput() {
    if (!this.button) return;
    // magic fix for auto-focusing, change detection doesn't work without this
    setTimeout(() => {
      this.button.nativeElement.focus();
    });
  }
}
