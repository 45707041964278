import { Pipe, PipeTransform } from '@angular/core';

import { FlexTimeActivityInstance } from 'minga/domain/flexTime';

import { FlexTimePermissionsService } from '@shared/services/flex-time/flex-time-permissions';

@Pipe({ name: 'canManageActivityRegistration' })
export class CanManageActivityRegistrationPipe implements PipeTransform {
  constructor(private _flexPermissions: FlexTimePermissionsService) {}

  transform(instance: FlexTimeActivityInstance): boolean {
    return this._flexPermissions.canManageActivityRegistration(instance);
  }
}
