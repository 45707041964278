import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';

import { NotificationActions } from 'minga/app/src/app/store/Notifications/actions';
import { NotificationStoreModel } from 'minga/app/src/app/store/Notifications/model';
import { INotificationType } from 'minga/domain/notification';
import {
  RealtimeEventType,
  RealtimeHallPassMeta,
} from 'minga/domain/realtimeEvents';
import { Notification } from 'minga/proto/gateway/notification_pb';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { HallpassEvents } from 'src/app/realtime-event/hallpass/HallpassEvents';

import { HpmRoutes } from '@modules/hallpass-manager';

import {
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { HallPassActionsService } from '@shared/services/hall-pass/hallpass-actions.service';
import {
  getSecondsTillPendingPassExpiry,
  isCurrentUserRequester,
} from '@shared/utils/hall-pass';

type HallPassMetaWithEndDate = RealtimeHallPassMeta & {
  endDateIsoString: string;
};

/**
 * Listen to incoming notifications to look for hallpass notifications
 */
@Injectable({ providedIn: 'root' })
export class HallPassNotificationHandlerService {
  private _hallpassNotificationsConfig: Record<
    string,
    {
      clickHandler?: (msg: Notification.AsObject) => void;
    }
  > = {
    [INotificationType.HALL_PASS_APPROVAL_REQUEST]: {
      clickHandler: async msg => {
        if (!msg.metadata) return this._router.navigate([HpmRoutes.ROOT]);

        const meta = JSON.parse(msg.metadata);

        // legacy notification lets just go to the hallpass page
        if (!meta?.hallPass || !meta.hallPass.endDateIsoString) {
          return this._router.navigate([HpmRoutes.ROOT]);
        }

        const isRequester = isCurrentUserRequester(
          this._authService.authPersonHash,
          meta.hallPass,
        );

        if (isRequester) {
          await this._showPendingCancellationDialog(meta.hallPass);
        } else {
          await this._showPendingApprovalDialog(meta.hallPass);
        }
      },
    },
    // student
    [INotificationType.HALL_PASS_APPROVED]: {
      clickHandler: async msg => {
        this._systemAlertModal.open({
          modalType: SystemAlertModalType.DEFAULT,
          heading: msg.title,
          message: msg.body,
          icon: 'mg-hallpass-menu',
        });
      },
    },
    // student
    [INotificationType.HALL_PASS_DENIED]: {},
    // student
    [INotificationType.HALL_PASS_GRANTED]: {
      clickHandler: async msg => {
        this._systemAlertModal.open({
          modalType: SystemAlertModalType.DEFAULT,
          heading: msg.title,
          message: msg.body,
          icon: 'mg-hallpass-menu',
        });
      },
    },
    // teacher
    [INotificationType.HALL_PASS_NOTIFY]: {},
    // student
    [INotificationType.HALL_PASS_APPROVAL_CANCEL]: {},
  };
  constructor(
    public store: Store<any>,
    private _router: Router,
    private _hallPassActions: HallPassActionsService,
    private _hallPassRealtime: HallpassEvents,
    private _authService: AuthInfoService,
    private _systemAlertModal: SystemAlertModalService,
  ) {}

  public isHallPassNotification(type) {
    return this._hallpassNotificationsConfig[type];
  }

  public async handleNotification(msg: Notification.AsObject) {
    const handler = this._hallpassNotificationsConfig[msg.notificationType];
    if (handler.clickHandler) {
      return handler.clickHandler(msg);
    }

    const notification: NotificationStoreModel = {
      id: msg.notificationId,
    };
    this.store.dispatch(
      new NotificationActions.MarkNotificationAsReadAction(notification),
    );
  }

  private async _showPendingCancellationDialog(meta: HallPassMetaWithEndDate) {
    const secondsToExpire = getSecondsTillPendingPassExpiry(
      meta.endDateIsoString,
    );
    const { issuedTo, passName, issuedBy, id } = meta;
    const recipient = {
      name: issuedTo.name,
      passId: id,
    };

    await this._hallPassActions.showPendingCancellationDialog(
      [recipient],
      passName,
      issuedBy.name,
      secondsToExpire,
    );
  }

  private async _showPendingApprovalDialog(meta: HallPassMetaWithEndDate) {
    const secondsToExpire = getSecondsTillPendingPassExpiry(
      meta.endDateIsoString,
    );

    if (secondsToExpire <= 0) {
      return this._systemAlertModal.open({
        modalType: SystemAlertModalType.ERROR,
        heading: 'Request for a hall pass has timed out',
        detailedMessage: [
          ['Hall pass', meta.passName],
          ['Approved by', meta.requestedBy.name],
          ['Student', meta.issuedTo.name],
        ],
        closeBtn: 'Close',
      });
    }

    const hallPass = {
      ...meta,
      secondsToExpire,
    };

    const normalized = {
      type: RealtimeEventType.HALL_PASS_APPROVAL_REQUEST,
      title: 'Hall pass request',
      hallPass,
    };

    await this._hallPassRealtime.handleEvent(normalized);
  }
}
