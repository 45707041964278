import { MingaColorsPaletteMap } from '@shared/types/mingaColors';
import { lightenColor, darkenColor } from '@shared/utils/colors';

export enum MingaColorsPaletteStates {
  INITIAL = 'initial',
  HOVER = 'hover',
  ACTIVE = 'active',
  PRESSED = 'pressed',
}

/**
 * @deprecated in favor of MINGA_APP_COLORS
 */
export enum MingaColors {
  AQUA = 'aqua',
  BLUE = 'blue',
  DARK_BLUE = 'dark-blue',
  GREEN = 'green',
  LIME = 'lime',
  NAVY = 'navy',
  ORANGE = 'orange',
  PINK = 'pink',
  RED = 'red',
  TEAL = 'teal',
  WHITE = 'white',
  YELLOW = 'yellow',
  KIWI = 'kiwi', // Flex Module Accent
  BLUE_GREEN = 'blue-green', // Flex Module Accent
}

/**
 * @deprecated in favor of MINGA_APP_COLORS
 */
export const MINGA_COLOR_TYPES = [
  'aqua',
  'blue',
  'dark-blue',
  'green',
  'lime',
  'navy',
  'orange',
  'pink',
  'red',
  'teal',
  'white',
  'yellow',
  'kiwi', // Flex Module Accent
  'blue-green', // Flex Module Primary
] as const;

export type MingaColorsType = typeof MINGA_COLOR_TYPES[number];

// changes here should also be reflected in css config app/src/styles/settings/palettes/_minga.scss
export const MINGA_GRAYSCALE = [
  'grey-10',
  'grey-20',
  'grey-40',
  'grey-50',
  'grey-100',
  'grey-200',
  'grey-300',
  'grey-400',
  'grey-500',
  'grey-600',
  'grey-700',
  'grey-800',
  'grey-900',
  'grey-950',
] as const;
export type MingaGreyPalette = typeof MINGA_GRAYSCALE[number];

// we can generate these types dynamically once we hit TS 4.1+
export type MingaPrimaryPalette =
  | 'primary-40'
  | 'primary-50'
  | 'primary-100'
  | 'primary-200'
  | 'primary-300'
  | 'primary-400'
  | 'primary-500'
  | 'primary-600'
  | 'primary-700'
  | 'primary-800'
  | 'primary-900'
  | 'primary-950';

// we can generate these types dynamically once we hit TS 4.1+
export type MingaSecondaryPalette =
  | 'secondary-40'
  | 'secondary-50'
  | 'secondary-100'
  | 'secondary-200'
  | 'secondary-300'
  | 'secondary-400'
  | 'secondary-500'
  | 'secondary-600'
  | 'secondary-700'
  | 'secondary-800'
  | 'secondary-900'
  | 'secondary-950';

// we can generate these types dynamically once we hit TS 4.1+
export type MingaTertiaryPalette =
  | 'tertiary-40'
  | 'tertiary-50'
  | 'tertiary-100'
  | 'tertiary-200'
  | 'tertiary-300'
  | 'tertiary-400'
  | 'tertiary-500'
  | 'tertiary-600'
  | 'tertiary-700'
  | 'tertiary-800'
  | 'tertiary-900'
  | 'tertiary-950';

// we can generate these types dynamically once we hit TS 4.1+
export type MingaErrorPalette =
  | 'error-40'
  | 'error-50'
  | 'error-100'
  | 'error-200'
  | 'error-300'
  | 'error-400'
  | 'error-500'
  | 'error-600'
  | 'error-700'
  | 'error-800'
  | 'error-900'
  | 'error-950';

// we can generate these types dynamically once we hit TS 4.1+
export type MingaWarningPalette =
  | 'warning-40'
  | 'warning-50'
  | 'warning-100'
  | 'warning-200'
  | 'warning-300'
  | 'warning-400'
  | 'warning-500'
  | 'warning-600'
  | 'warning-700'
  | 'warning-800'
  | 'warning-900'
  | 'warning-950';

// we can generate these types dynamically once we hit TS 4.1+
export type MingaSuccessPalette =
  | 'success-40'
  | 'success-50'
  | 'success-100'
  | 'success-200'
  | 'success-300'
  | 'success-400'
  | 'success-500'
  | 'success-600'
  | 'success-700'
  | 'success-800'
  | 'success-900'
  | 'success-950';

export const MINGA_APP_COLORS = [
  'primary',
  'secondary',
  'tertiary',
  // Grayscale
  'transparent',
  'white',
  'black',
  // Surface
  'surface',
  'surface-alt',
  // Outline
  'outline',
  'outline-alt',
  // Disabled
  'disabled-bg',
  'on-disabled-bg',
  // Error
  'error',
  'error-bg',
  'on-error-bg',
  // Warning
  'warn',
  'warn-bg',
  'on-warn-bg',
  // Success
  'success',
  'success-bg',
  'on-success-bg',
  // Module Colors
  'hall-pass',
  'behavior',
  'community',
  'flextime',
  'check-in',
  'minga-manager',
  'id-manager',

  'surface-card',
  'surface-card-high',
  'surface-card-highest',
  'behavior-praise',
  'behavior-consequence',
] as const;

export type MingaPresetColors = typeof MINGA_APP_COLORS[number];

/**
 * Updates to any of these need to be reflected in our scss definitions `app/src/styles/settings/palettes/_minga.scss`
 */
export type MingaAppColor =
  | MingaPresetColors
  | MingaGreyPalette
  | MingaPrimaryPalette
  | MingaSecondaryPalette
  | MingaTertiaryPalette
  | MingaErrorPalette
  | MingaWarningPalette
  | MingaSuccessPalette;

/**
 * @deprecated in favor of MINGA_APP_COLORS
 */
export const MINGA_COLOR_PALETTE: MingaColorsPaletteMap = {
  [MingaColors.AQUA]: {
    [MingaColorsPaletteStates.INITIAL]: {
      color: '#78EFD9',
      fontColor: '#003366',
    },
    [MingaColorsPaletteStates.HOVER]: {
      color: lightenColor('#78EFD9', 0.1),
      fontColor: '#003366',
    },
    [MingaColorsPaletteStates.ACTIVE]: {
      color: darkenColor('#78EFD9', 0.1),
      fontColor: '#003366',
    },
    [MingaColorsPaletteStates.PRESSED]: {
      color: darkenColor('#78EFD9', 0.1),
      fontColor: '#003366',
    },
  },
  [MingaColors.BLUE]: {
    [MingaColorsPaletteStates.INITIAL]: {
      color: '#2B8DC3',
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.HOVER]: {
      color: lightenColor('#2B8DC3', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.ACTIVE]: {
      color: darkenColor('#2B8DC3', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.PRESSED]: {
      color: darkenColor('#2B8DC3', 0.1),
      fontColor: '#FFF',
    },
  },
  [MingaColors.DARK_BLUE]: {
    [MingaColorsPaletteStates.INITIAL]: {
      color: '#1d47b9',
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.HOVER]: {
      color: lightenColor('#1d47b9', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.ACTIVE]: {
      color: darkenColor('#1d47b9', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.PRESSED]: {
      color: darkenColor('#1d47b9', 0.1),
      fontColor: '#FFF',
    },
  },
  [MingaColors.GREEN]: {
    [MingaColorsPaletteStates.INITIAL]: {
      color: '#4BA083',
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.HOVER]: {
      color: lightenColor('#4BA083', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.ACTIVE]: {
      color: darkenColor('#4BA083', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.PRESSED]: {
      color: darkenColor('#4BA083', 0.1),
      fontColor: '#FFF',
    },
  },
  [MingaColors.LIME]: {
    [MingaColorsPaletteStates.INITIAL]: {
      color: '#6FEF99',
      fontColor: '#003366',
    },
    [MingaColorsPaletteStates.HOVER]: {
      color: lightenColor('#6FEF99', 0.1),
      fontColor: '#003366',
    },
    [MingaColorsPaletteStates.ACTIVE]: {
      color: darkenColor('#6FEF99', 0.1),
      fontColor: '#003366',
    },
    [MingaColorsPaletteStates.PRESSED]: {
      color: darkenColor('#6FEF99', 0.1),
      fontColor: '#003366',
    },
  },
  [MingaColors.NAVY]: {
    [MingaColorsPaletteStates.INITIAL]: {
      color: '#003366',
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.HOVER]: {
      color: lightenColor('#003366', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.ACTIVE]: {
      color: darkenColor('#003366', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.PRESSED]: {
      color: darkenColor('#003366', 0.1),
      fontColor: '#FFF',
    },
  },
  [MingaColors.ORANGE]: {
    [MingaColorsPaletteStates.INITIAL]: {
      color: '#DAAC64',
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.HOVER]: {
      color: lightenColor('#DAAC64', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.ACTIVE]: {
      color: darkenColor('#DAAC64', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.PRESSED]: {
      color: darkenColor('#DAAC64', 0.1),
      fontColor: '#FFF',
    },
  },
  [MingaColors.PINK]: {
    [MingaColorsPaletteStates.INITIAL]: {
      color: '#db6578',
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.HOVER]: {
      color: lightenColor('#db6578', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.ACTIVE]: {
      color: darkenColor('#db6578', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.PRESSED]: {
      color: darkenColor('#db6578', 0.1),
      fontColor: '#FFF',
    },
  },
  [MingaColors.RED]: {
    [MingaColorsPaletteStates.INITIAL]: {
      color: '#943028',
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.HOVER]: {
      color: lightenColor('#943028', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.ACTIVE]: {
      color: darkenColor('#943028', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.PRESSED]: {
      color: darkenColor('#943028', 0.1),
      fontColor: '#FFF',
    },
  },
  [MingaColors.TEAL]: {
    [MingaColorsPaletteStates.INITIAL]: {
      color: '#1D9FB9',
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.HOVER]: {
      color: lightenColor('#1D9FB9', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.ACTIVE]: {
      color: darkenColor('#1D9FB9', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.PRESSED]: {
      color: darkenColor('#1D9FB9', 0.1),
      fontColor: '#FFF',
    },
  },
  [MingaColors.WHITE]: {
    [MingaColorsPaletteStates.INITIAL]: {
      color: '#FFF',
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.HOVER]: {
      color: lightenColor('#FFF', 0.1),
      fontColor: '#003366',
    },
    [MingaColorsPaletteStates.ACTIVE]: {
      color: darkenColor('#FFF', 0.1),
      fontColor: '#FFF',
    },
    [MingaColorsPaletteStates.PRESSED]: {
      color: darkenColor('#FFF', 0.1),
      fontColor: '#FFF',
    },
  },
  [MingaColors.YELLOW]: {
    [MingaColorsPaletteStates.INITIAL]: {
      color: '#D6E261',
      fontColor: '#003366',
    },
    [MingaColorsPaletteStates.HOVER]: {
      color: lightenColor('#D6E261', 0.1),
      fontColor: '#003366',
    },
    [MingaColorsPaletteStates.ACTIVE]: {
      color: darkenColor('#D6E261', 0.1),
      fontColor: '#003366',
    },
    [MingaColorsPaletteStates.PRESSED]: {
      color: darkenColor('#D6E261', 0.1),
      fontColor: '#003366',
    },
  },
  // Flex Module Accent
  [MingaColors.KIWI]: {
    [MingaColorsPaletteStates.INITIAL]: {
      color: '#b2e573',
      fontColor: '#003366',
    },
    [MingaColorsPaletteStates.HOVER]: {
      color: lightenColor('#b2e573', 0.1),
      fontColor: '#003366',
    },
    [MingaColorsPaletteStates.ACTIVE]: {
      color: darkenColor('#b2e573', 0.1),
      fontColor: '#003366',
    },
    [MingaColorsPaletteStates.PRESSED]: {
      color: darkenColor('#b2e573', 0.1),
      fontColor: '#003366',
    },
  },
  // Flex Module Primary
  [MingaColors.BLUE_GREEN]: {
    [MingaColorsPaletteStates.INITIAL]: {
      color: '#21A28E',
      fontColor: '#fff',
    },
    [MingaColorsPaletteStates.HOVER]: {
      color: lightenColor('#21A28E', 0.1),
      fontColor: '#fff',
    },
    [MingaColorsPaletteStates.ACTIVE]: {
      color: darkenColor('#21A28E', 0.1),
      fontColor: '#fff',
    },
    [MingaColorsPaletteStates.PRESSED]: {
      color: darkenColor('#21A28E', 0.1),
      fontColor: '#fff',
    },
  },
};

/**
 * Helper to retrieve color hex value based on color name in javascript
 */
export const getAppColor = (color: MingaAppColor): string => {
  return getComputedStyle(document.documentElement).getPropertyValue(
    `--mg-color-${color}`,
  );
};
