import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import * as day from 'dayjs';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  from,
  of,
} from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { FlexTimePeriod } from 'minga/domain/flexTime';
import {
  RESTRICTION_ERRORS,
  RestrictionErrorMinimal,
} from 'minga/domain/restrictions';
import { mingaSettingTypes } from 'minga/util';
import { AuthInfoService } from 'src/app/minimal/services/AuthInfo';
import { RootService } from 'src/app/minimal/services/RootService';
import { HallPassService } from 'src/app/services/HallPass';
import { MingaSettingsService } from 'src/app/store/Minga/services';

import { ActivityFilterKey } from '@modules/kiosk/types/kiosk-flextime.types';
import { PeopleSelectorService } from '@modules/people-selector';
import { DEFAULT_STAFF_PASS_DURATION } from '@modules/selection-assigner';

import {
  CloseTimeoutDuration,
  SystemAlertModalService,
} from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { getAppColor } from '@shared/constants';
import { CheckinService } from '@shared/services/checkin';
import {
  FlexTimePeriodService,
  FlexTimePermissionsService,
} from '@shared/services/flex-time';

import { KioskCheckInMessage } from '../../constants';
import {
  KioskAudioService,
  KioskCheckInService,
  KioskHallPassService,
  KioskService,
} from '../../services';
import {
  CheckInType,
  KioskCheckInData,
  KioskCheckInFormSteps,
  KioskCheckInTypeData,
  KioskFlexTypeData,
  KioskHallPassSummaryData,
  KioskPerson,
  KioskType,
} from '../../types';
import { KioskFormAbstractComponent } from '../../utils';

@Component({
  selector: 'mg-kiosk-check-in',
  templateUrl: './kiosk-check-in.component.html',
  styleUrls: ['./kiosk-check-in.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [KioskCheckInService, KioskHallPassService, FlexTimePeriodService],
})
export class KioskCheckInComponent
  extends KioskFormAbstractComponent<KioskCheckInFormSteps, KioskCheckInData>
  implements OnInit
{
  public CHECK_IN_TYPE = CheckInType;
  public readonly MSG = KioskCheckInMessage;
  public readonly MESSAGES = KioskCheckInMessage;
  public readonly periodControl = new FormControl(null);
  public readonly activityControl = new FormControl(null);
  private _authHash = this._authInfo.authPersonHash;
  // self manager is teacher or staff (not manager+)
  public readonly isSelfManager =
    this._flexTimePermissionsService.isFlexTimeSelfManager();

  private readonly _summaryData = new BehaviorSubject<KioskHallPassSummaryData>(
    null,
  );
  public readonly summaryData$ = this._summaryData.asObservable();

  public kioskCheckInEnabled$ = this._settingsService.getSettingValueObs(
    mingaSettingTypes.CHECKIN_KIOSK,
  );
  public kioskFlexCheckInEnabled$ = this._settingsService.getSettingValueObs(
    mingaSettingTypes.FTM_KIOSK,
  );

  private _selectedCheckInTypeSubject = new BehaviorSubject<CheckInType>(
    CheckInType.CHECK_IN,
  );
  public readonly selectedCheckInType$ =
    this._selectedCheckInTypeSubject.asObservable();

  public readonly allTypes$ = this.fetchTypes();

  private _resetSuject = new Subject<void>();
  public readonly reset$ = this._resetSuject.asObservable();

  // check in types
  public readonly checkInTypes$ = this.loadingObservableWrapper(
    from(this._checkinService.getReasons(true, false, true)),
  ).pipe(
    takeUntil(this._destroyedSubject),
    map(types => {
      return types
        .sort((a, b) => {
          if (a.priority < b.priority) return -1;
          if (a.priority > b.priority) return 1;
          return 0;
        })
        .map((type): KioskType<KioskCheckInTypeData> => {
          return {
            id: type.id,
            name: type.name,
            icon: type.icon,
            color: type.color,
            data: {
              hallPassId: type.hallPassId,
              enableStudentPhoto: type.enableStudentPhoto,
            },
          };
        });
    }),
  );

  // flex check in
  private _flexPeriodsSubject = new BehaviorSubject<FlexTimePeriod[] | null>(
    null,
  ); // we init with null so we know when we've fetched periods for caching
  public readonly flexPeriods$ = this._flexPeriodsSubject.asObservable();

  public readonly flexPeriodsFilter$ = this.flexPeriods$.pipe(
    map(periods => {
      if (!periods) return [];
      return periods.map(period => {
        return {
          label: period.title,
          value: period.id,
        };
      });
    }),
  );

  public readonly flexActivitiesFilters = [
    {
      label: KioskCheckInMessage.FLEX_ACTIVITY_ALL_ACTIVITIES,
      value: ActivityFilterKey.ALL,
    },
    {
      label: KioskCheckInMessage.FLEX_ACTIVITY_MY_ACTIVITIES,
      value: ActivityFilterKey.MINE,
    },
  ];

  public readonly flexTypes$: Observable<KioskType<KioskFlexTypeData>[]> =
    combineLatest([
      this.periodControl.valueChanges,
      this.activityControl.valueChanges,
      this.flexPeriods$,
    ]).pipe(
      debounceTime(100),
      map(([periodId, activityFilter, periods]) => {
        if (!periods) return [];

        const period = periods.find(period => period.id === periodId);
        const activites = period?.activityInstances || [];

        if (!activites.length) return [];

        const filtered =
          activityFilter === ActivityFilterKey.MINE
            ? activites.filter(
                activity =>
                  activity.flexTimeActivity?.createdByPerson?.hash ===
                  this._authHash,
              )
            : activites;

        return filtered.map((activity, i): KioskType<KioskFlexTypeData> => {
          return {
            id: activity.checkinReasonId,
            name: activity.flexTimeActivity?.name,
            icon: 'mg-flextime-menu-o',
            color: getAppColor('primary'),
            // for teachers we want to pre-select the first activity since they wont have multiple
            initiallySelected: i === 0 && this.isSelfManager,
            data: {
              enableStudentPhoto: false,
            },
          };
        });
      }),
    );

  constructor(
    public kiosk: KioskService,
    public kioskSound: KioskAudioService,
    public root: RootService,
    public router: Router,
    public systemSnack: SystemAlertSnackBarService,
    public systemAlert: SystemAlertModalService,
    private _checkinService: CheckinService,
    private _peopleSelector: PeopleSelectorService,
    private _kioskHallPass: KioskHallPassService,
    private _hallPassService: HallPassService,
    private _flexTimePeriodsService: FlexTimePeriodService,
    private _flexTimePermissionsService: FlexTimePermissionsService,
    private _authInfo: AuthInfoService,
    private _settingsService: MingaSettingsService,
  ) {
    super(kiosk, router);
  }

  ngOnInit() {
    this.updateAvailableTypes();
    this._setDefaultCheckInTab();
    this._setupFlexData();
    this._setupSelectionReset();
  }

  // Hooks
  onDestroyed() {
    this._summaryData.complete();
  }

  onReset() {
    this._summaryData.next(null);
  }

  // Public methods
  public fetchTypes() {
    return combineLatest([
      this.kiosk.isKioskMode$,
      this.selectedCheckInType$,
    ]).pipe(
      takeUntil(this._manuallyStopEmittingSubject),
      switchMap(([isKioskMode, type]) => {
        const selectedObservable =
          type === CheckInType.CHECK_IN ? this.checkInTypes$ : this.flexTypes$;

        return selectedObservable.pipe(
          tap(() => {
            if (isKioskMode) this._manuallyStopEmittingSubject.next();
          }),
        );
      }),
    );
  }

  public async handleUserTypeSelection(
    selection: KioskType<KioskCheckInTypeData>[],
  ) {
    if (!selection.length) return;
    const [type] = selection;
    this.setSelectedType(type);
    await this.assign();
  }

  public changeTab(type: CheckInType) {
    this._selectedCheckInTypeSubject.next(type);
  }

  public async assign(person = this.getPerson()) {
    try {
      this.setAssignmentInProgress(true);
      this.setPerson(person);
      const type = this.getSelectedType() as KioskType<KioskCheckInTypeData>;
      if (!type) {
        this.setFormStep('type-selector');
      } else {
        const response = await this._checkinService.createCheckin(
          [person.personHash],
          type.id,
          {
            createdViaKiosk: true,
          },
        );

        if (response?.errors.length > 0) {
          await this._handleError(response.errors, person, type);
        } else if (response?.successPeopleHashes.length > 0) {
          await this._handleSuccess(person, type);
        }
      }
    } catch (error) {
      this.systemSnack.error('Error creating check in: ' + error?.message);
    } finally {
      this.setAssignmentInProgress(false);
    }
  }

  private async _handleSuccess(
    person: KioskPerson,
    type: KioskType<KioskCheckInTypeData>,
  ) {
    const profilePicture = type.data.enableStudentPhoto
      ? person.profilePicture
      : undefined;
    await this.kioskSound.play('success');
    await this._peopleSelector.openDialog({
      type: 'success',
      title: person.displayName,
      subTitle: `Checked in`,
      message: type.name,
      closeTimeout: profilePicture
        ? CloseTimeoutDuration.MEDIUM
        : CloseTimeoutDuration.SHORT,
      profilePicture,
    });

    // if this checkin is associated with a hall pass automation to send user to summary screen of newly created hall pass
    if (type.data.hallPassId) {
      try {
        const summary = await this._getSummaryData(
          type.data.hallPassId,
          person,
        );
        this._summaryData.next(summary);
        this.setFormStep('summary');
        this.startAutoClose();
        // if there's an error generating the summary lets fallback to just returning to the start screen
      } catch (e) {
        this.reset();
      }
    } else {
      this.reset();
    }
  }

  private async _handleError(
    errors: RestrictionErrorMinimal[],
    person: KioskPerson,
    type: KioskType<KioskCheckInTypeData>,
  ) {
    const { code } = errors[0];
    const title = person.displayName;
    const profilePicture = type.data.enableStudentPhoto
      ? person.profilePicture
      : undefined;

    await this.kioskSound.play('error');
    await this._peopleSelector.openDialog({
      type: 'error',
      title,
      subTitle: RESTRICTION_ERRORS[code].title || `Check in denied`,
      message: type.name,
      canContinue: false,
      extendedMessage:
        (RESTRICTION_ERRORS[code].message ||
          'Some unknown error has occured while trying to process your request') +
        '. Go see your staff member to be checked in manually',
      profilePicture,
      closeTimeout: CloseTimeoutDuration.LONG,
    });
    this.reset();
  }

  private _setupFlexData() {
    this.selectedCheckInType$
      .pipe(
        takeUntil(this._destroyedSubject),
        filter(type => type === CheckInType.FLEX),
      )
      .subscribe(async type => {
        const periods = await this._flexFetchPeriods().toPromise();
        this._flexPeriodsSubject.next(periods);
        this.periodControl.setValue(periods[0]?.id);
        this.activityControl.setValue(
          this.isSelfManager ? ActivityFilterKey.MINE : ActivityFilterKey.ALL,
        );
      });
  }
  private _flexFetchPeriods() {
    const periods = this._flexPeriodsSubject.value;
    if (periods !== null) return of(periods);
    return this.loadingObservableWrapper(
      from(
        this._flexTimePeriodsService.fetchAll(day(), day(), this.isSelfManager),
      ),
    );
  }

  private _setDefaultCheckInTab() {
    combineLatest([this.kioskCheckInEnabled$, this.kioskFlexCheckInEnabled$])
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(([checkIn, flex]) => {
        if (!checkIn && flex) {
          this._selectedCheckInTypeSubject.next(CheckInType.FLEX);
        }
      });
  }

  private _setupSelectionReset() {
    combineLatest([
      this.selectedCheckInType$,
      this.periodControl.valueChanges,
      this.activityControl.valueChanges,
    ])
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(vals => {
        this._resetSuject.next();
      });
  }

  /**
   * We can't actually get the the hall pass that was created easily since it runs in background via automated job
   * But we can just need to show a summary page so we dont need the pass itself
   */
  private async _getSummaryData(
    hallPassTypeId: number,
    person,
  ): Promise<KioskHallPassSummaryData> {
    const hallPassType = await this._hallPassService.getHallPassTypeById(
      hallPassTypeId,
    );

    const duration =
      hallPassType.defaultHallPassTime || DEFAULT_STAFF_PASS_DURATION;
    const startDayJs = day();
    const start = startDayJs.toDate();
    const end = startDayJs.add(duration, 'minute').toDate();

    return {
      name: hallPassType.name,
      iconName: hallPassType.bannerHash,
      iconColor: hallPassType.color,
      studentName: person.displayName,
      endingMethod: this._kioskHallPass.getPassEndingMethod(
        hallPassType.manuallyEndPass,
      ),
      start,
      end,
      expire: end,
    };
  }
}
