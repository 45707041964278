import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { IBellSchedule } from 'minga/domain/bellSchedule';

import { SCHEDULE_FORM_FIELDS } from '../constants/mm-bs-schedules.constants';
import { getOverlappingDays } from './noOverlappingDays.validator';

export const noOverlappingDaysByTermValidator = (
  schedules: IBellSchedule[],
): ValidatorFn => {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const days = formGroup.get(SCHEDULE_FORM_FIELDS.DAYS)?.value as string[];
    const terms = formGroup.get(SCHEDULE_FORM_FIELDS.TERMS)?.value as number[];

    if (!terms?.length || !days?.length) return null;

    const filteredSchedules = schedules.filter(schedule => {
      const intersectingTerms = schedule.terms.some(term =>
        terms.includes(term.id),
      );

      return intersectingTerms;
    });

    const overlappingDays = getOverlappingDays(filteredSchedules, days);

    if (overlappingDays.length === 0) return null;

    return {
      overlappingDaysByTerm: overlappingDays,
    };
  };
};
