import { InjectionToken } from '@angular/core';

import { Term } from 'minga/domain/term/Term.domain';

import {
  ClientTerm,
  TermId,
} from '@modules/minga-manager/components/mm-bell-schedule/types/mm-bell-schedule.types';

import { UpdateListCacheParams } from '../cache/cache.utils';

/** Define the interface token for the dependency injection provider. */
export const BellScheduleTermsInterface =
  new InjectionToken<BellScheduleTermsService>('BellScheduleTermsService');

/**
 * Bell Schedule Terms
 *
 * CRUD methods for school terms.
 */
export interface BellScheduleTermsService {
  /**
   * Fetch all terms. It's possible that no terms exist. It's also
   * possible the terms are cached.
   */
  fetchAll(opts?: { revalidate?: boolean }): Promise<ClientTerm[]>;

  /**
   * Get the term with the specified id. It's possible the term does not
   * exist for the specified id.
   *
   * @throws TODO: exception type
   */
  get(id: TermId): Promise<ClientTerm>;

  /** Create the term */
  create(term: Partial<Term>): Promise<Term>;

  /** Update the term */
  update(term: Partial<ClientTerm>): Promise<Term>;

  /** Delete the term with the specified id. */
  delete(id: TermId): Promise<void>;

  /** Update the cache. */
  updateListCache(
    opts: UpdateListCacheParams<ClientTerm>,
  ): Promise<ClientTerm[]>;
}
