import { FlexTimeActivityInstance } from 'minga/domain/flexTime';
import { day } from 'minga/shared/day';

import { getAppColor } from '@shared/constants';

import { SELECTION_ASSIGNER_MODAL_CONFIG } from '../constants';
import { CarouselTileData, SaSelectorTypes } from '../types';

export const mapFlexActivitesToCarouselData = (
  activities: FlexTimeActivityInstance[],
): CarouselTileData[] => {
  return activities.map(activity => {
    return {
      type: 'flex-activity',
      id: activity.id,
      name: activity.flexTimeActivity.name,
      icon: 'mg-flextime-menu-o',
      color: getAppColor('flextime'),
      periodId: activity.flexTimePeriodId,
    };
  });
};

export const hasFilterField = (selector: SaSelectorTypes): boolean => {
  const modalConfig = SELECTION_ASSIGNER_MODAL_CONFIG[selector];
  if (modalConfig?.tabbedHeader === true)
    return modalConfig?.data?.find(d => d.selector === selector)?.filterField;
  else return modalConfig?.data?.filterField;
};

export const combineDateAndTime = (
  date: day.Dayjs,
  time: string,
): day.Dayjs => {
  const timeSegments = time.split(':');
  return date
    .hour(parseInt(timeSegments[0], 10))
    .minute(parseInt(timeSegments[1], 10));
};
