import { Inject, Injectable, Optional } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import * as SemVer from 'semver';
import { filter, takeWhile } from 'rxjs/operators';

import { AppConfigService } from 'minga/app/src/app/minimal/services/AppConfig';

import { HomeRoute } from '@modules/home/constants';

import {
  SystemAlertCloseEvents,
  SystemAlertModalService,
  SystemAlertModalType,
} from '@shared/components/system-alert-modal';
import { AppRuntime, AppRuntimeInterface } from '@shared/services/app-runtime';
import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';

import { LinkOpenerService } from '../LinkOpener';

/**
 * Service to handle smoothly updating the app when updates are available.
 */
@Injectable({ providedIn: 'root' })
export class AppUpdateService {
  constructor(
    @Optional() private _swUpdate: SwUpdate,
    private _router: Router,
    private _appConfig: AppConfigService,
    private _systemAlertModal: SystemAlertModalService,
    @Inject(AppRuntimeInterface) private _runtime: AppRuntime,
    private _linkOpener: LinkOpenerService,
    private _kioskPermissions: KioskPermissionsService,
  ) {}

  init() {
    // only do this if there is a service worker.
    if (this._swUpdate) {
      this._swUpdate.available.subscribe(event => {
        this.updateToLatest();
      });
      if (this._swUpdate.isEnabled) {
        this._swUpdate.checkForUpdate();
      }
    }

    // min app version value is fetched from firebase remote config
    // value can be set in firebase remote config console
    const minimumVersion = this._appConfig.getValue('minimumVersion');
    if (minimumVersion) {
      this.checkAppVersion(minimumVersion);
    }
  }

  updateToLatest() {
    if (this._kioskPermissions.isUserInKioskMode()) {
      this._handleKioskUpdateFlow();
    } else {
      this._handleStandardUpdateFlow();
    }
  }

  private _handleStandardUpdateFlow() {
    // wait until the user goes to the home page to launch notifier
    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeWhile(this.isNotOnHomepage, true),
      )
      .subscribe((event: Event) => {
        if (!this.isNotOnHomepage(event)) {
          this.showUpdateDialog();
        }
      });
  }

  /**
   * We want to be more forceful with the update dialog in kiosk mode
   * Dialog is non dismissable and we're not gonna wait to show the dialog
   */
  private _handleKioskUpdateFlow() {
    this.showUpdateDialog(false);
  }

  private isNotOnHomepage(event: Event) {
    if (event instanceof NavigationEnd) {
      if (event.urlAfterRedirects === `/${HomeRoute.ROOT}`) {
        return false;
      }
    }

    return true;
  }

  /**
   * Check if the app version meets the passed in version, and if not,
   * redirect to the old version notifier.
   *
   * @param minimumVersion
   * @param router
   */
  async checkAppVersion(minimumVersion: string) {
    const isMinimum = await this.isAppMinimumVersion(minimumVersion);
    if (!isMinimum) {
      setTimeout(() => {
        this.updateToLatest();
      }, 2000);
    }
  }

  /**
   * Check if the app version meets the passed in version.
   *
   * @param minimumVersion
   */
  async isAppMinimumVersion(minimumVersion: string): Promise<boolean> {
    if (!minimumVersion) {
      // if there was no minimum version passed, assume we are at the minimum
      // version.
      return true;
    }
    const version = this._appConfig.getAppVersion();
    return SemVer.gte(version, minimumVersion);
  }

  public async showUpdateDialog(dismissable = true) {
    const modalRef = await this._systemAlertModal.open({
      modalType: SystemAlertModalType.DEFAULT,
      heading: 'Update available!',
      message:
        'This new version brings performance updates and other bug fixes',
      closeBtn: dismissable ? 'Update later' : '',
      confirmActionBtn: 'Update now',
      disableClose: !dismissable,
      icon: 'resolved-o-active',
    });

    const response = await modalRef.afterClosed().toPromise();

    if (response?.type === SystemAlertCloseEvents.CONFIRM) {
      this._update();
    }
  }

  /**
   * 1. If the app is a cordova app, open the app store link.
   * 2. If the app is a web app, update the service worker and reload the page.
   */
  private async _update() {
    if (this._runtime.isNativeApp('ios')) {
      this._linkOpener.open(this._appConfig.getValue('iosAppStoreUrl'));
    } else if (this._runtime.isNativeApp('android')) {
      this._linkOpener.open(this._appConfig.getValue('androidAppStoreUrl'));
    } else {
      if (this._swUpdate) {
        this._swUpdate.activateUpdate().then(() => {
          this._router.navigate(['']).then(() => document.location.reload());
        });
      }
    }
  }
}
