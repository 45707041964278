import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl } from '@angular/forms';

import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mg-form-toggle',
  templateUrl: './form-toggle.component.html',
  styleUrls: ['./form-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormToggleComponent implements OnInit, OnDestroy {
  /** Misc Subjects */
  private _destroyedSubject = new ReplaySubject<void>(1);

  /** Form Control */
  public formControl = this._fb.control(false);

  /** Inputs */
  @Input() label: string;
  @Input() secondaryLabel: string;
  @Input() tooltip: string;
  @Input() set disabled(isDisabled: boolean) {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
    this._cdr.detectChanges();
  }
  /**
   * Unique id for things like analytics and testing to hook into
   * Important to note changing this could break either of those
   */
  @Input() id: string;

  @Input()
  set control(formControl: FormControl | AbstractControl) {
    this.formControl = formControl as FormControl;
    this._cdr.markForCheck();
  }

  @Input() set value(val: boolean) {
    this.formControl.reset(val, { emitEvent: false });
  }

  @Output() valueChange = new EventEmitter<boolean>();

  get toggleDisabled() {
    return this.formControl.disabled;
  }

  /** Component Constructor */
  constructor(private _cdr: ChangeDetectorRef, private _fb: FormBuilder) {}

  ngOnInit(): void {
    this.formControl.valueChanges
      .pipe(takeUntil(this._destroyedSubject))
      .subscribe(val => {
        this.formControl.markAsDirty();
        return this.valueChange.emit(val);
      });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
  }
}
