<mg-manager-overlay-subpage
  *ngIf="layout.useLegacyLayout$ | async; else newLayoutTemplate"
  [loading]="false">
  <ng-template #headerLeft>
    <h2 class="header-title">{{ MESSAGES.SUBPAGE_TITLE }}</h2>
  </ng-template>
  <ng-template #headerRight>
    <div
      fxFlex="noshrink"
      fxLayoutAlign="end end"
      fxLayoutAlign.xs="center center">
      <mg-form-date
        label="Date"
        [labelBackground]="(layout.useLegacyLayout$ | async) ? 'grey' : 'white'"
        [control]="dateControl">
      </mg-form-date>
    </div>
  </ng-template>

  <ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>
</mg-manager-overlay-subpage>

<!-- New layout template -->
<ng-template #newLayoutTemplate>
  <ng-container *ngTemplateOutlet="mainContentTemplate"></ng-container>
</ng-template>

<!-- Main template content -->
<ng-template #mainContentTemplate>
  <!-- Page Content -->
  <ng-container *ngTemplateOutlet="quickActionsTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="todaysPeriodsTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="activitiesTemplate"></ng-container>
  <div
    *ngIf="ftmDashboard.isLoading$ | async"
    class="loading-container">
    <mg-spinner
      [diameter]="40"
      [thickness]="3">
    </mg-spinner>
  </div>
</ng-template>

<!-- Quick Actions Template -->
<ng-template #quickActionsTemplate>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start stretch"
    fxLayoutGap.lt-md="8px"
    class="quick-action-container">
    <div fxFlex.gt-sm="nogrow">
      <mg-form-date
        label="Date"
        [id]="'flextime-dashboard-click-date'"
        [labelBackground]="(layout.useLegacyLayout$ | async) ? 'grey' : 'white'"
        [control]="dateControl">
      </mg-form-date>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="end center"
      fxLayoutAlign.lt-md="space-between center"
      fxLayoutGap="15px"
      fxHide.lt-md>
      <div
        fxFlex="nogrow"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="8px">
        <mg-form-checkbox
          [id]="'flextime-dashboard-click-auto-refresh'"
          [checked]="ftmDashboard.pollState$ | async"
          [disabled]="ftmDashboard.isLoading$ | async"
          (changed)="ftmDashboard.togglePollingState()">
        </mg-form-checkbox>
        <mg-text variant="body-xs-secondary">
          {{ MESSAGES.AUTO_REFRESH }}
        </mg-text>
      </div>
      <div fxFlex="nogrow">
        <mg-btn
          variant="text"
          size="small"
          iconSet="material"
          icon="refresh"
          [id]="'flextime-dashboard-click-refresh'"
          (pressed)="ftmDashboard.refreshDashboard()">
          {{ MESSAGES.BUTTON_LABEL_REFRESH }}
        </mg-btn>
      </div>
    </div>
  </div>
</ng-template>

<!-- Todays Periods Template -->
<ng-template #todaysPeriodsTemplate>
  <div class="section-container">
    <div
      fxLayout="row"
      fxLayoutGap="4px"
      fxLayoutAlign="start end"
      class="period-title-container">
      <mg-text
        fxFlex="nogrow"
        variant="header-lg">
        {{ MESSAGES.PERIODS_TITLE }}
      </mg-text>
      <div
        fxFlex="nogrow"
        fxFlexAlign="center"
        class="separator"></div>
      <mg-text
        fxFlex="nogrow"
        class="current-date"
        variant="header-md-secondary">
        {{ dateControl.value | date: 'EEEE, MMM d' }}
      </mg-text>
    </div>
    <ng-container *ngIf="(periodTiles$ | async)?.length > 0; else noPeriods">
      <div class="card-grid">
        <ng-container *ngFor="let period of periodTiles$ | async">
          <ng-container
            [ngTemplateOutlet]="cardTemplate"
            [ngTemplateOutletContext]="{
              $implicit: period,
              type: 'period'
            }">
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- Activities Template -->
<ng-template #activitiesTemplate>
  <div class="section-container mt-large">
    <mg-text
      class="section-title"
      variant="header-lg"
      spacing="2">
      {{ MESSAGES.ACTIVITIES_TITLE }}
    </mg-text>
    <div
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutAlign="start center"
      fxLayoutAlign.lt-sm="stretch"
      fxLayoutGap="16px"
      class="filters-container">
      <mg-form-select
        fxFlex.lt-sm="100%"
        mgTeacherSearch
        placeholder="Teacher"
        class="teacher-select"
        [id]="'flextime-dashboard-click-teacher'"
        [closeOnSelect]="true"
        [multiple]="false"
        [searchable]="true"
        [hideSelected]="false"
        [floatingLabel]="false"
        [isClearable]="true"
        [enableMobileSheet]="true"
        (selectionChanged)="onTeacherSelectChange($event)">
      </mg-form-select>
      <div
        fxLayout="row wrap"
        fxLayoutAlign="start center"
        fxLayoutGap="16px grid">
        <ng-container *ngFor="let item of periodTiles$ | async">
          <mg-chip
            icon="mg-flextime-menu-o"
            iconColor="flextime"
            size="large"
            [subTitle]="item | formatTimeRange"
            [active]="(ftmDashboard.periodFilter$ | async) === item.id"
            (pressed)="setActivePeriod(item.id)">
            {{ item.name }}
          </mg-chip>
        </ng-container>
      </div>
    </div>
    <ng-container *ngIf="teacherHasActivity$ | async; else noTeachers">
      <ng-container
        *ngIf="(activityInstances$ | async).length > 0; else noActivities">
        <div class="card-grid alternative">
          <ng-container *ngFor="let activity of activityInstances$ | async">
            <ng-container
              [ngTemplateOutlet]="cardTemplate"
              [ngTemplateOutletContext]="{
                $implicit: activity,
                type: 'activity'
              }">
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<!-- Card Template -->
<ng-template
  #cardTemplate
  let-data
  let-type="type">
  <ng-container
    *ngIf="
      type === 'period' ||
      (selectedTeacherHash$ | async) === '' ||
      data.teacherHash === (selectedTeacherHash$ | async)
    ">
    <mg-card [ngClass]="type === 'period' ? 'period-card' : 'activity-card'">
      <!-- Card Header -->
      <div
        fxLayout="row"
        fxLayoutAlign="start start">
        <div
          class="card-title-container"
          fxFlex="auto">
          <ng-container *ngIf="data | getPeriodStatus as status">
            <div class="mb-small">
              <mg-tag
                *ngIf="type === 'period'"
                [color]="
                  status === 'Not Started'
                    ? 'blue'
                    : status === 'Started'
                    ? 'green'
                    : 'grey'
                ">
                {{ status }}
              </mg-tag>
            </div>
          </ng-container>
          <mg-text
            [title]="data.name"
            [spacing]="type === 'period' ? '0-half' : '0'"
            [variant]="type === 'period' ? 'label-md' : 'header-sm-secondary'"
            [numberOfLines]="1"
            [color]="type === 'period' ? 'surface-alt' : null">
            {{ data.name }}
          </mg-text>
          <div
            fxLayout="row"
            fxLayoutAlign="start center">
            <mg-icon
              class="flex-icon"
              *ngIf="type === 'period'">
              mg-flextime-menu-o
            </mg-icon>
            <mg-text
              *ngIf="type === 'period'"
              variant="header-lg-secondary"
              class="ml-small">
              {{ data | formatTimeRange }}
            </mg-text>
            <mg-text
              *ngIf="type === 'activity'"
              variant="label-md"
              color="surface-alt"
              [numberOfLines]="1"
              [title]="data.teacherName + ' / ' + data.location">
              {{ data.teacherName + ' / ' + data.location }}
            </mg-text>
          </div>
        </div>
        <div fxFlex="nogrow">
          <mg-btn
            class="top-right"
            icon="mg-flex-report"
            variant="icon"
            iconSet="minga"
            [id]="
              type === 'period'
                ? 'flextime-dashboard-click-period'
                : 'flextime-dashboard-click-activity-card'
            "
            (pressed)="
              goToReports(
                type === 'period'
                  ? { periodId: data.id }
                  : {
                      periodId: data.flexTimePeriodId,
                      activityId: data.flexTimeActivityId
                    }
              )
            ">
          </mg-btn>
        </div>
      </div>
      <!-- Card Body -->
      <div class="card-menu">
        <mg-menu-item
          badgeColor="blue"
          [id]="'flextime-dashboard-click-registered'"
          [badgeLabel]="data | getStudentRegistered: type"
          [tagSize]="type === 'period' ? 'large' : 'small'"
          (pressed)="
            goToReports({
              periodId: type === 'period' ? data.id : data.flexTimePeriodId,
              activityId: type === 'activity' ? data.flexTimeActivityId : null
            })
          ">
          {{ MESSAGES.STUDENTS_REGISTERED }}
        </mg-menu-item>
        <mg-menu-item
          *ngIf="type === 'period'"
          badgeLabel="{{ data.totalSpaces - data.count }}"
          [id]="'flextime-dashboard-click-not-registered'"
          [badgeColor]="data.totalSpaces - data.count > 0 ? 'yellow' : 'blue'"
          [tagSize]="type === 'period' ? 'large' : 'small'"
          (pressed)="
            goToReports({
              periodId: type === 'period' ? data.id : data.flexTimePeriodId,
              goToUnregistered: true
            })
          ">
          {{ MESSAGES.STUDENTS_NOT_REGISTERED }}
        </mg-menu-item>
        <mg-menu-item
          badgeColor="blue"
          [id]="'flextime-dashboard-click-checked-in'"
          [badgeLabel]="data | calculateStudentCheckedIn: type"
          [tagSize]="type === 'period' ? 'large' : 'small'"
          (pressed)="
            goToReports({
              periodId: type === 'period' ? data.id : data.flexTimePeriodId,
              activityId: type === 'activity' ? data.flexTimeActivityId : null,
              goToCheckedIn: true
            })
          ">
          {{ MESSAGES.STUDENTS_CHECKED_IN }}
        </mg-menu-item>
        <mg-menu-item
          badgeLabel="{{ data.absentees }}"
          [id]="'flextime-dashboard-click-not-checked-in'"
          [badgeColor]="data | getButtonStatusColor"
          [tagSize]="type === 'period' ? 'large' : 'small'"
          (pressed)="
            goToReports({
              periodId: type === 'period' ? data.id : data.flexTimePeriodId,
              activityId: type === 'activity' ? data.flexTimeActivityId : null,
              goToAbsents: true
            })
          ">
          {{ MESSAGES.STUDENTS_NOT_CHECKED_IN }}
        </mg-menu-item>
      </div>
    </mg-card>
  </ng-container>
</ng-template>

<ng-template #noPeriods>
  <mg-empty-state
    [title]="MESSAGES.NO_PERIODS_TITLE"
    [subtitle]="MESSAGES.NO_PERIODS_DESC">
  </mg-empty-state>
</ng-template>

<ng-template #noActivities>
  <mg-empty-state
    [title]="MESSAGES.NO_ACTIVITIES_TITLE"
    [subtitle]="MESSAGES.NO_ACTIVITIES_DESC">
  </mg-empty-state>
</ng-template>

<ng-template #noTeachers>
  <mg-empty-state
    [title]="MESSAGES.NO_TEACHERS_TITLE"
    [subtitle]="MESSAGES.NO_TEACHERS_DESC">
  </mg-empty-state>
</ng-template>
