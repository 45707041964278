import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';

import { getAppColor } from '@shared/constants';
import { smartLightenColor } from '@shared/utils/colors';

export type IconVariant = 'raised-accent' | 'primary';

@Component({
  selector: 'mg-icon',
  templateUrl: './MgIcon.component.html',
  styleUrls: ['./MgIcon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MgIconComponent {
  _iconNamespace = 'minga';

  @ViewChild('svgIconName', { static: true })
  svgIconNameEl?: ElementRef;

  @Input() iconName: string;
  @Input() iconColor: string;
  @Input() variant: IconVariant = 'primary';

  @Input('icon-namespace')
  set namespace(value: string) {
    if (value) {
      this._iconNamespace = value;
    }
  }

  @HostBinding('class') get variantClass() {
    return this.variant ? this.variant : '';
  }

  @HostBinding('style.backgroundColor') get backgroundColor() {
    return this._getBackgroundColor();
  }

  getSvgIconName() {
    if (this.iconName) {
      return `${this._iconNamespace}:${this.iconName}`;
    }
    if (this.svgIconNameEl) {
      const svgIconName = this.svgIconNameEl.nativeElement.textContent.trim();

      if (svgIconName) {
        return `${this._iconNamespace}:${svgIconName}`;
      }
    }

    return '';
  }

  private _getBackgroundColor() {
    try {
      if (!this.variant) return '';

      if (this.variant === 'raised-accent') {
        const color = this.iconColor || getAppColor('surface');
        return smartLightenColor(color);
      }

      return '';
    } catch (e) {
      return '';
    }
  }
}
