<section
  class="card"
  *ngIf="item?.activity"
  fxLayout="column"
  fxLayoutAlign="start start">
  <div
    fxLayout="row"
    fxLayoutAlign="center stretch"
    [style.width.%]="100">
    <div
      fxFlex="nogrow"
      class="card-image">
      <mg-ftm-activity-img
        [bannerImage]="item.activity.bannerImage"
        [imagePath]="item.activity.imagePath"
        [aspectRatio]="1 / 1"></mg-ftm-activity-img>
    </div>
    <div
      fxFlex="grow"
      class="card-details">
      <mg-text
        *ngIf="item.activity.name && visibleFields.includes(FIELDS.NAME)"
        variant="label-md-secondary"
        spacing="0-half">
        {{ item.activity.name }}
      </mg-text>
      <mg-text
        *ngIf="
          item.activity.description &&
          visibleFields.includes(FIELDS.DESCRIPTION)
        "
        variant="label-sm"
        [numberOfLines]="2"
        spacing="0-half">
        {{ item.activity.description }}
      </mg-text>
    </div>
    <div
      fxFlex="nogrow"
      class="card-actions"
      fxLayout="column"
      fxLayoutAlign="space-between flex-end">
      <ng-container *ngIf="actions">
        <ng-container *ngTemplateOutlet="actions"></ng-container>
      </ng-container>
      <ng-container *ngIf="!actions">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between center">
          <mg-text
            *ngIf="item.activity.type?.name as activityType"
            variant="label-sm"
            fontWeight="900"
            color="grey-400"
            textTransform="uppercase">
            {{ activityType }}
          </mg-text>
          <ng-container *ngIf="item.instance">
            <mg-icon
              *ngIf="
                isStaff && !(item?.instance | canManageActivityRegistration)
              "
              class="card-lock">
              lock-icon
            </mg-icon>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="card-divider"></div>
  <div
    fxLayout="row"
    fxLayoutAlign="space-between center"
    [style.width.%]="100">
    <ng-container
      *ngIf="
        item.activity.createdByPerson && visibleFields.includes(FIELDS.TEACHER)
      "
      [ngTemplateOutlet]="labeledDetailTemplate"
      [ngTemplateOutletContext]="{
        $implicit: MESSAGES.TEACHER,
        value: item.activity.createdByPerson.name
      }">
    </ng-container>
    <ng-container
      *ngIf="visibleFields.includes(FIELDS.SEATS) && item.instance"
      [ngTemplateOutlet]="labeledDetailTemplate"
      [ngTemplateOutletContext]="{
        $implicit: MESSAGES.SEATS_AVAILABLE,
        value: (item.instance | activityRegistered: true)
      }">
    </ng-container>
    <ng-container
      *ngIf="visibleFields.includes(FIELDS.LOCATION) && item.activity.location"
      [ngTemplateOutlet]="labeledDetailTemplate"
      [ngTemplateOutletContext]="{
        $implicit: MESSAGES.LOCATION,
        value: item.activity.location
      }">
    </ng-container>
  </div>
</section>

<ng-template
  #labeledDetailTemplate
  let-label
  let-value="value">
  <div
    fxLayout="row"
    fxlayoutAlign="center center"
    fxLayoutGap="5px">
    <mg-text
      variant="label-sm"
      textAlign="center"
      fontWeight="900">
      {{ label }}
    </mg-text>
    <mg-text
      variant="label-sm"
      textAlign="center">
      {{ value }}
    </mg-text>
  </div>
</ng-template>
