import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule, Location } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  Injectable,
  Injector,
  NgModule,
  NgZone,
  forwardRef,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
  HammerGestureConfig,
  HammerModule,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import * as Sentry from '@sentry/angular';
import * as hammerjs from 'hammerjs';
import { grpc } from '@improbable-eng/grpc-web';
import { waitForCordovaDeviceReady } from '@lib/cordova/device-ready';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatDayjsDateModule } from '@tabuckner/material-dayjs-adapter';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { QuillModule } from 'ngx-quill';
import 'quill';

import { BarcodeScanner } from 'minga/app/src/app/barcodeScanner';
import { CordovaBarcodeScanner } from 'minga/app/src/app/barcodeScanner/cordova';
import { MgButtonModule } from 'minga/app/src/app/button';
import { DialogModule } from 'minga/app/src/app/components/Dialog';
import { LoaderOverlayModule } from 'minga/app/src/app/components/LoaderOverlay';
import { ContentStoreModule } from 'minga/app/src/app/content-common/store/module';
import 'minga/app/src/app/elements/MgMentionQuillModule';
import { FileModule } from 'minga/app/src/app/file';
import { FirebaseMessaging } from 'minga/app/src/app/firebase/messaging';
import { FirebaseMessagingCordova } from 'minga/app/src/app/firebase/messaging/firebase-cordova';
import { FirebaseMessagingWeb } from 'minga/app/src/app/firebase/messaging/firebase-web';
import { FirebaseMessagingFirestoreFallback } from 'minga/app/src/app/firebase/messaging/firestore-fallback';
import { WelcomeModule } from 'minga/app/src/app/ftue/Welcome';
import { GroupsFacadeService } from 'minga/app/src/app/groups/services';
import { MingaSnackIconModule } from 'minga/app/src/app/minimal/components/MingaSnackIcon';
import { AnalyticsService } from 'minga/app/src/app/minimal/services/Analytics';
import { AppConfigService } from 'minga/app/src/app/minimal/services/AppConfig';
import { AuthService } from 'minga/app/src/app/minimal/services/Auth';
import { DeviceUpdaterService } from 'minga/app/src/app/minimal/services/DeviceUpdater';
import { MgModalModule } from 'minga/app/src/app/minimal/services/MgModal';
import { MingaSnackModule } from 'minga/app/src/app/minimal/services/MingaSnack';
import { MgModalContainerModule } from 'minga/app/src/app/modal';
import { MessagingStoreModule } from 'minga/app/src/app/modules/direct-message/store/module';
import { MgOverlayModule } from 'minga/app/src/app/overlay';
import { PeopleModule } from 'minga/app/src/app/people';
import { FirebaseMessagingRealtimeDataConsumer } from 'minga/app/src/app/realtime';
import { RolesEffects } from 'minga/app/src/app/roles/effects';
import { SearchOverlayModule } from 'minga/app/src/app/search-overlay';
import { GradesEffects } from 'minga/app/src/app/services/Grades';
import { UserStorage } from 'minga/app/src/app/services/UserStorage';
import { ROUTER_DERIVED_STATE_GROUP_FETCHER } from 'minga/app/src/app/state/tokens/routerDerivedStateGroupFetcher';
import { AuthStoreModule } from 'minga/app/src/app/store/AuthStore';
import { RootEffects } from 'minga/app/src/app/store/root/rootEffects';
import { RootEffectsModule } from 'minga/app/src/app/store/root/rootEffects.module';
import { URL_METADATA_STORAGE } from 'minga/app/src/app/ugc-links/services';
import { createStorageInstance } from 'minga/app/src/app/util/storage';
import { getFirebaseConfig, getVersion } from 'minga/app/src/environment/index';
import { firebase } from 'minga/app/src/firebase';
import 'minga/app/src/grpc-web-client-patch';
import { MingaFlexBreakPointsProvider } from 'minga/app/src/styles/flex-breakpoints';
import {
  RealtimeDataConsumer,
  RealtimeDirectMessageConsumer,
  RealtimeNotificationConsumer,
} from 'minga/shared/realtime';
import { TokenStatus } from 'minga/util';
import { WebcamBarcodeScanner } from 'src/app/barcodeScanner/webcam/WebcamBarcodeScanner';
import { createErrorHandler } from 'src/app/error-handler';
import { SentryService } from 'src/app/minimal/services/Sentry/Sentry.service';
import { StartupService } from 'src/app/minimal/services/Startup/Startup.service';
import { MingaStoreFacadeService } from 'src/app/store/Minga/services';
import { LinkDetailModule } from 'src/app/ugc-links/containers/LinkDetail';

import { LayoutModule } from '@modules/layout';

import { ModalOverlayService } from '@shared/components/modal-overlay';
import { SwitchMingaModalModule } from '@shared/components/switch-minga-modal/switch-minga-modal.module';
import { SystemAlertModalModule } from '@shared/components/system-alert-modal';
import { SystemAlertSnackBarModule } from '@shared/components/system-alert-snackbar';
import { WebcamModalModule } from '@shared/components/webcam-modal';
import {
  AppRuntimeDefault,
  AppRuntimeInterface,
} from '@shared/services/app-runtime';
import { KioskPermissionsService } from '@shared/services/kiosk/kiosk-permissions.service';
import { FlexLayoutWorkaround } from '@shared/services/media/flex-layout-workaround.service';
import { UserpilotService } from '@shared/services/userpilot/Userpilot.service';

import { buildEnvironment } from '../environment/build';
import './MatIconRegistryPatch';
import { AppRoutingModule } from './app-routing.module';
import { CustomSerializer, appReducers, appRootStoreConfig } from './app-store';
import { AppComponent } from './app.component';
import { MG_MAT_FORM_FIELD_OPTIONS } from './constants';
import { IconModule } from './icon.module';
import { AuthInfoService } from './minimal/services/AuthInfo';
import { canUseWebPushApi } from './notifications/NotificationPermission.service';
import { patchCordovaBrowserTabPlugin } from './patchCordovaBrowserTabPlugin';
import { RealtimeEventHandlers } from './realtime-event/RealtimeEventHandlers';
import { RealtimeEvents } from './realtime-event/RealtimeEvents';
import { MingaStoreModule } from './store/Minga';
import { startMultiOutletHack } from './util';

@Injectable()
export class MgHammerConfig extends HammerGestureConfig {
  overrides = {
    panright: { direction: hammerjs.DIRECTION_RIGHT },
    panleft: { direction: hammerjs.DIRECTION_LEFT },
    pandown: { direction: hammerjs.DIRECTION_DOWN },
    swipeleft: { direction: hammerjs.DIRECTION_LEFT },
    swiperight: { direction: hammerjs.DIRECTION_RIGHT },
    tap: { enable: true },
  };

  options = {
    // To allow stop propogations on taps
    domEvents: true,
  };

  public buildHammer(element: HTMLElement) {
    return new hammerjs(element);
  }
}

const setupInterceptor = (auth: AuthService, injector: Injector) => {
  window.addGrpcMetadataInterceptor({
    onBeforeSend: (metadata: grpc.Metadata) => {
      const existingToken = metadata.get('token');
      // trigger a refresh of the token if the one we currently have
      // is expired. Since getting a new token is async, if it's expired
      // we will have to settle for this request inevitably failing, but this
      // is at least a fallback to ensure it gets updated for the next request.
      auth.refreshTokenIfExpired();
      // Only set a token if one wasn't set.
      if (existingToken.length === 0) {
        metadata.set('token', auth.getLastIdToken());
      }

      metadata.set('ngsw-bypass', 'true');
      metadata.set('mg-client-version', getVersion());
    },
    onReceive: (metadata: grpc.Metadata) => {
      if (!metadata.has('mg-status')) {
        return;
      }
      const status = parseInt(metadata.get('mg-status').toString(), 10);
      if (status === TokenStatus.VALID) {
        // All good, fam!
      } else if (status === TokenStatus.EXPIRED) {
        auth.authExpiredSnackbar();
      } else if (status === TokenStatus.MINGA_PAUSED) {
        const router: Router = injector.get(Router);
        const ngZone: NgZone = injector.get(NgZone);
        const location: Location = injector.get(Location);
        if (!location.path().startsWith('/landing')) {
          ngZone.run(() =>
            router.navigateByUrl('/paused/minga', {
              skipLocationChange: true,
            }),
          );
        }
      } else {
        console.warn('Unhandled TokenStatus', status);
      }
    },
  });
};

const appInitializer = (
  auth: AuthService,
  authInfo: AuthInfoService,
  appConfigService: AppConfigService,
  location: Location,
  injector: Injector,
  analytics: AnalyticsService,
  userStorage: UserStorage,
  messaging: FirebaseMessaging,
  sentry: SentryService,
  mingaStore: MingaStoreFacadeService,
  startupService: StartupService,
  userpilotService: UserpilotService,
  flexWorkaround: FlexLayoutWorkaround,
  realtimeEvents: RealtimeEvents,
  realtimeEventHandlers: RealtimeEventHandlers,
) => {
  return async () => {
    try {
      await mingaStore.startup();
      userpilotService.initialize();
      startMultiOutletHack(location);
      await waitForCordovaDeviceReady();
      patchCordovaBrowserTabPlugin();
      const firebaseConfig = getFirebaseConfig();
      firebase.initializeApp(firebaseConfig);
      // need to manually initialize analytics on web
      if (window.MINGA_APP_BROWSER) {
        firebase.analytics();
      }
      await appConfigService.startup();
      await messaging.init();
      userStorage.init();
      setupInterceptor(auth, injector);
      await auth.startup();
      await realtimeEvents.init();
      realtimeEventHandlers.init();

      const deviceUpdater: DeviceUpdaterService =
        injector.get(DeviceUpdaterService);
      deviceUpdater.startup();
      startupService.startup();
      flexWorkaround.initialize();
    } finally {
      const router = injector.get(Router);
      router.initialNavigation();
      await analytics.init(router);
    }
  };
};

const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    LayoutModule,

    // Routing
    AppRoutingModule,

    // Minga dependencies
    IconModule,
    MgOverlayModule,
    MatIconModule,
    MatSnackBarModule,
    LoaderOverlayModule,
    MgModalContainerModule,
    MgModalModule,
    SearchOverlayModule,
    FileModule.forRoot(),
    RootEffectsModule,
    PeopleModule,
    MingaSnackIconModule,
    MingaSnackModule,
    LinkDetailModule,
    MgButtonModule,
    DialogModule,
    WelcomeModule,
    SystemAlertModalModule,
    SystemAlertSnackBarModule,
    WebcamModalModule,
    SwitchMingaModalModule,

    // External dependencies
    CommonModule,
    PlatformModule,
    MatSelectModule,
    PortalModule,
    FlexLayoutModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDayjsDateModule,
    MatMenuModule,
    QuillModule.forRoot(),
    StoreModule.forRoot(appReducers, appRootStoreConfig),
    StoreDevtoolsModule.instrument({
      name: 'Minga store',
    }),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    EffectsModule.forRoot([RootEffects, RolesEffects, GradesEffects]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ServiceWorkerModule.register('/minga-sw.js', {
      enabled: buildEnvironment.production && window.MINGA_APP_BROWSER,
    }),
    MingaStoreModule,
    AuthStoreModule,
    MessagingStoreModule,
    ContentStoreModule,
    HammerModule,
    OverlayModule,
    GoogleTagManagerModule,
  ],
  providers: [
    {
      provide: AppRuntimeInterface,
      /**
       * Using a factory because window might not be defined in the early
       * stages of the build.
       *
       * Using 'any' here as a workaround because the runtime is temporarily
       * defining it's own Window interface. See notes on runtime.interface.ts
       * for why that is. When that Window interface is removed, this can be
       * updated to remove the any.
       */
      useFactory: () => {
        return new AppRuntimeDefault(window as any);
      },
    },
    {
      provide: ErrorHandler,
      useFactory: (router: Router) => {
        return createErrorHandler(router, {
          showDialog: false,
        });
      },
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
      useValue: undefined,
    },
    {
      provide: ROUTER_DERIVED_STATE_GROUP_FETCHER,
      useClass: forwardRef(() => GroupsFacadeService),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [
        AuthService,
        AuthInfoService,
        AppConfigService,
        Location,
        Injector,
        AnalyticsService,
        UserStorage,
        FirebaseMessaging,
        Sentry.TraceService,
        StartupService,
        MingaStoreFacadeService,
        UserpilotService,
        // @TODO remove once we get to angular v13+
        // hack flex layout service resolves: https://github.com/angular/flex-layout/issues/1201
        FlexLayoutWorkaround,
        RealtimeEvents,
        RealtimeEventHandlers,
      ],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: MG_MAT_FORM_FIELD_OPTIONS,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MgHammerConfig,
    },

    {
      provide: URL_METADATA_STORAGE,
      useFactory: () =>
        createStorageInstance({
          name: 'urlMetadata',
          // 5 minutes
          memoryStorageTimeout: 300000,
        }),
    },
    {
      provide: FirebaseMessaging,
      deps: [AuthInfoService, KioskPermissionsService],
      useFactory: (
        authInfo: AuthInfoService,
        kioskPermissions: KioskPermissionsService,
      ) => {
        const isWebPushApiEnabled = canUseWebPushApi();

        // If the user has explicitly denied push notifications the push api
        // will be unavailable. We resort to using the firestore fallback to
        // still give them real time notifications.
        if (window.MINGA_APP_BROWSER && isWebPushApiEnabled) {
          return new FirebaseMessagingWeb(kioskPermissions);
        } else if (!window.MINGA_APP_BROWSER) {
          return new FirebaseMessagingCordova(kioskPermissions);
        } else {
          console.warn('Using fallback for messaging');
          return new FirebaseMessagingFirestoreFallback(
            authInfo.authPersonHash$,
            kioskPermissions,
          );
        }
      },
    },
    {
      provide: RealtimeDataConsumer,
      deps: [FirebaseMessaging],
      useFactory: (messaging: FirebaseMessaging) => {
        return new FirebaseMessagingRealtimeDataConsumer(messaging);
      },
    },
    {
      provide: RealtimeDirectMessageConsumer,
      deps: [RealtimeDataConsumer],
      useFactory: (dataConsumer: RealtimeDataConsumer) =>
        new RealtimeDirectMessageConsumer(dataConsumer),
    },
    {
      provide: RealtimeNotificationConsumer,
      deps: [RealtimeDataConsumer],
      useFactory: (dataConsumer: RealtimeDataConsumer) =>
        new RealtimeNotificationConsumer(dataConsumer),
    },
    {
      provide: 'googleTagManagerId',
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        const config = appConfigService.getGtmConfig();
        return config.id;
      },
    },

    {
      provide: 'googleTagManagerAuth',
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        const config = appConfigService.getGtmConfig();
        return config.gtm_auth;
      },
    },
    {
      provide: 'googleTagManagerPreview',
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        const config = appConfigService.getGtmConfig();
        return config.gtm_preview;
      },
    },
    {
      provide: BarcodeScanner,
      deps: [ModalOverlayService],
      useFactory: (modalOverlayService: ModalOverlayService) => {
        if (window.MINGA_APP_BROWSER) {
          return new WebcamBarcodeScanner(modalOverlayService);
        } else {
          return new CordovaBarcodeScanner();
        }
      },
    },
    MingaFlexBreakPointsProvider,
    ModalOverlayService,
  ],
  exports: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
