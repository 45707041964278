import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import * as day from 'dayjs';

import { TERM_FORM_FIELDS } from '../constants/mm-bs-terms.constants';
import { ClientTerm } from '../types/mm-bell-schedule.types';

export const noOverlappingTermsValidator = (
  terms: ClientTerm[],
): ValidatorFn => {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const { start, end } = formGroup.get(TERM_FORM_FIELDS.DATE_RANGE)?.value;

    if (!start || !end) return null;

    // need to loop through the other terms to make sure non of them overlap with the current term we're editing
    const overlappingTerms = terms
      .filter(t => t.startDate && t.endDate)
      .filter(t => {
        const termStart = day(t.startDate);
        const termEnd = day(t.endDate);

        const currentStartsBeforeTermEnds = start.isBefore(termEnd);
        const currentEndsAfterTermStarts = end.isAfter(termStart);
        const termStartsBeforeCurrentEnds = termStart.isBefore(end);
        const termEndsAfterCurrentStarts = termEnd.isAfter(start);

        return (
          (currentStartsBeforeTermEnds && currentEndsAfterTermStarts) ||
          (termStartsBeforeCurrentEnds && termEndsAfterCurrentStarts)
        );
      });

    if (overlappingTerms.length === 0) return null;

    return {
      overlappingTerms,
    };
  };
};
