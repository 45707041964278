import { InjectionToken } from '@angular/core';

/** Define the interface token for the dependency injection provider. */
export const AppRuntimeInterface = new InjectionToken<AppRuntime>('AppRuntime');

/**
 * AppRuntime
 *
 * Get information about the user's runtime environment, such as their device
 * and/or browser.
 *
 * This should only be used for platform feature queries, and not screen size
 * (resolution) queries, eg. don't use this for determining style rules. It
 * should be used for determining whether to show/hide, or use alternate
 * solutions for feature implementation based on the client platform.
 */
export interface AppRuntime {
  /**
   * Check if the app is a cordova/native build. We often need very different
   * solutions for native apps (cordova) specifically, regardless of iOS or
   * Android. Optionally you can also check what platform.
   */
  isNativeApp(platform?: Platform): boolean;

  /**
   * Check if the app is running in a browser, and optionally check what
   * platform.
   */
  isBrowserApp(platform?: Platform): boolean;

  /**
   * Get information about the device. The amount of information available
   * depends on the type of runtime environment. For example, the native app
   * will have more information than the browser.
   */
  getDeviceInfo(): Promise<DeviceInfo>;

  /**
   * Check if the device has a physical attribute, eg. home button, notch,
   * etc. The presence, or absence, of these attributes can affect how a user
   * interacts or views the app. Such as a camera notch requiring more top
   * padding, or the lack of a home button requires more bottom padding.
   *
   * NOTE: At this time, an attribute is different from a feature. A feature
   * will be something like a camera, lidar, geolocation, accelerometer, etc.
   * A feature will usually correspond to a cordova plugin, and each one will
   * have a different interface for interacting with it. Eg. camera requires
   * permissions and capture settings, file downloader just needs to openUrl,
   * accelerometer might output a stream of xyz vectors… etc.
   *
   * Not sure if this is the best approach, as they are very similar.
   */
  hasAttribute(attribute: Attribute): Promise<boolean>;
}

export type Platform = 'ios' | 'android';

/**
 * Attributes are physical characteristics of the device that might affect how
 * we display content.
 *
 * TODO: 'notch' | 'camera-front'?
 */
export type Attribute = 'homebutton';

/** Device info can be native or browser. */
export type DeviceInfo = NativeDeviceInfo | BrowserInfo;

/**
 * NativeDeviceInfo is a direct copy of CordovaPluginDevice in device.types.ts.
 */
export type NativeDeviceInfo = {
  /** Get the version of Cordova running on the device. */
  cordova: string;

  /** Indicates that Cordova initialize successfully. */
  available: boolean;

  /**
   * The device.model returns the name of the device's model or product. The value is set
   * by the device manufacturer and may be different across versions of the same product.
   */
  model: string;

  /** Get the device's operating system name. */
  platform: string;

  /** Get the device's Universally Unique Identifier (UUID). */
  uuid: string;

  /** Get the operating system version. */
  version: string;

  /** Get the device's manufacturer. */
  manufacturer: string;

  /** Whether the device is running on a simulator. */
  isVirtual: boolean;

  /** Get the device hardware serial number. */
  serial: string;

  /** The browser/app user agent */
  userAgent: string;
};

export type BrowserInfo = {
  /**
   * TODO: Add platform: get the device's operating system name.
   * platform: string; (oscpu)
   */
  userAgent: string;
};

/**
 * At this time, all we care about is that our Window has a navigator and user
 * agent. Intentionally not using the Window defined in `app/src/globals.ts`
 * until all of the attributes in that file are migrated into the AppRuntime.
 * Once that is done, then this Window will be merged with the one in globals.
 * - drop navitagor, document, and update the device type in globals.
 */
export interface Window {
  /** This is meant to be a minimal copy of the DOM navigator. */
  navigator: { userAgent: string };

  /** This is meant to be a copy of the DOM document. */
  document: Document;

  /** Device will not be set for non-native (browser) apps. */
  device?: NativeDeviceInfo;
}
