import { HOME_BUTTON_IOS_MODEL_CODES } from '@shared/constants/homeButtonIphoneModels';

import { DeviceAttribute } from './attribute.interface';

/**
 * TODO:
 * Consider moving the model codes file "homeButtonIphoneModels.ts" to be
 * co-located, or ideally merged into this file. Making those const values
 * private, should minimize future devs misusing them.
 */
export class HomeButtonAttribute implements DeviceAttribute {
  isAvailableOn(model?: string): boolean {
    if (model) {
      return HOME_BUTTON_IOS_MODEL_CODES.includes(model);
    } else {
      return false;
    }
  }
}
