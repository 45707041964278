import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { FlexTimePeriod } from 'minga/domain/flexTime';

import {
  MODAL_OVERLAY_DATA,
  ModalOverlayPrimaryHeaderBackground,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { SystemAlertSnackBarData } from '@shared/components/system-alert';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { FlexTimePeriodService } from '@shared/services/flex-time';
import { FlexTimePermissionsService } from '@shared/services/flex-time/flex-time-permissions';

import {
  ActivityCardFields,
  ActivityListFilters,
  ActivitySelectorModalData,
  ActivitySelectorModalFormFields,
  ActivitySelectorModalResponse,
  FtmSharedMessages,
} from '../../ftm-shared.constants';

@Component({
  selector: 'mg-ftm-activity-selector-modal',
  templateUrl: './ftm-activity-selector-modal.component.html',
  styleUrls: ['./ftm-activity-selector-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FtmActivitySelectorModalComponent implements OnInit, OnDestroy {
  public headerBackground = ModalOverlayPrimaryHeaderBackground.ALT_TEAL;
  public MESSAGES = FtmSharedMessages;
  public FORM_FIELDS = ActivitySelectorModalFormFields;
  public CARD_FIELDS = [
    ActivityCardFields.NAME,
    ActivityCardFields.DESCRIPTION,
    ActivityCardFields.TEACHER,
    ActivityCardFields.SEATS,
  ];

  private readonly _destroyed = new ReplaySubject<void>(1);

  public modalTitle =
    this.data?.selectorType === 'assign'
      ? FtmSharedMessages.MODAL_TITLE_ASSIGN
      : FtmSharedMessages.MODAL_TITLE_REGISTER;

  private _formState = new BehaviorSubject<'idle' | 'loading'>('loading');
  public formState$ = this._formState.asObservable();

  private _flexPeriodsSubj = new BehaviorSubject<FlexTimePeriod[]>([]);
  public flexPeriods$ = this._flexPeriodsSubj.asObservable();

  private _filterChangeSub = new BehaviorSubject<ActivityListFilters>({
    periodFilter: this.data?.periodId || null,
    teacherFilter: this.data?.teacher?.hash || '',
    searchFilter: '',
  });

  private _filterChange$ = this._filterChangeSub.asObservable();

  private _isFlexTimeRegistrant =
    this._flexPermissionService.isFlexTimeRegistrant();

  public activityInstances$ = combineLatest([
    this.flexPeriods$,
    this._filterChange$,
  ]).pipe(
    map(([periods, filters]) => {
      const { periodFilter, searchFilter, teacherFilter } = filters;

      const filteredByPeriod = periodFilter
        ? periods.filter(p => p.id === periodFilter)
        : periods;

      let activityInstances = filteredByPeriod.reduce((acc, p) => {
        return acc.concat(p.activityInstances || []);
      }, []);

      activityInstances = activityInstances.map(ai => {
        const period = periods.find(p => p.id === ai.flexTimePeriodId);
        ai.flexTimePeriod = period;
        return ai;
      });

      const filterFunctions = [];

      // filtering functions
      const filterByCurrentActivityId = a =>
        a.id !== this.data.currentActivityId;
      const filterByTeacher = a =>
        a.flexTimeActivity?.createdByPerson?.hash === teacherFilter;
      const filterBySearch = a =>
        a.flexTimeActivity?.name
          ?.toLowerCase()
          .includes(searchFilter.trim().toLowerCase());
      const filterByAllowStudentsToRegister = a =>
        a.flexTimeActivity?.allowStudentToRegister;

      if (this.data?.currentActivityId)
        filterFunctions.push(filterByCurrentActivityId);
      if (teacherFilter) filterFunctions.push(filterByTeacher);
      if (searchFilter) filterFunctions.push(filterBySearch);
      if (this._isFlexTimeRegistrant)
        filterFunctions.push(filterByAllowStudentsToRegister);

      // using reduce so we only iterate over the items once just in case this
      // list gets big
      return activityInstances.reduce((result, obj) => {
        if (filterFunctions.every(filterFn => filterFn(obj))) {
          result.push(obj);
        }
        return result;
      }, []);
    }),
  );

  constructor(
    @Inject(MODAL_OVERLAY_DATA) public data: ActivitySelectorModalData,
    private _modalRef: ModalOverlayRef<
      ActivitySelectorModalResponse,
      ActivitySelectorModalData
    >,
    private _flexPeriods: FlexTimePeriodService,
    private _fb: FormBuilder,
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    private _flexPermissionService: FlexTimePermissionsService,
  ) {}

  ngOnInit(): void {
    this.fetchPeriods(this.data || ({} as any));
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public async fetchPeriods({
    startDate,
    endDate,
    periodId,
  }: ActivitySelectorModalData) {
    try {
      this._formState.next('loading');

      // if we're filtering by period id we only need to fetch that one
      const periods = periodId
        ? [await this._flexPeriods.fetch(periodId)]
        : await this._flexPeriods.fetchAll(startDate, endDate);
      this._flexPeriodsSubj.next(periods);
    } catch (e) {
      this._systemAlertSnackBar.open({
        type: 'error',
        message: FtmSharedMessages.MODAL_ERROR_FETCHING_ACTIVITIES,
      });
    } finally {
      this._formState.next('idle');
    }
  }

  public async select(activityInstance) {
    const isStaff = this._flexPermissionService.isFlexTimeSelfManager();
    const canManageActivityRegistration =
      this._flexPermissionService.canManageActivityRegistration(
        activityInstance,
      );

    if (isStaff && !canManageActivityRegistration) {
      const flexTimePeriod = this._flexPeriodsSubj.value.find(
        p => p.id === activityInstance.flexTimePeriodId,
      );
      if (!flexTimePeriod?.enableStudentManagement) {
        const config: SystemAlertSnackBarData = {
          type: 'error',
          message: 'Activity is locked',
        };
        this._systemAlertSnackBar.open(config);
        return;
      }
    }

    this._modalRef.close(ModalOverlayServiceCloseEventType.CREATE, {
      activityInstance,
    });
  }

  public onFilterChange(event: ActivityListFilters) {
    this._filterChangeSub.next(event);
  }
}
