import { ChangeDetectionStrategy, Inject } from '@angular/core';
import { Component } from '@angular/core';

import {
  MODAL_OVERLAY_DATA,
  ModalOverlayRef,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';

import { BS_ERROR_MESSAGES } from '../../constants/mm-bs-errors.constants';
import { MmBsErrorsComponentData } from '../../types/mm-bell-schedule.types';

@Component({
  selector: 'mg-mm-bs-errors',
  templateUrl: './mm-bs-errors.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmBsErrorsComponent {
  public MESSAGES = BS_ERROR_MESSAGES;

  constructor(
    @Inject(MODAL_OVERLAY_DATA)
    private _data: MmBsErrorsComponentData,
    private _modalRef: ModalOverlayRef<any, MmBsErrorsComponentData>,
  ) {}

  public get errors() {
    return this._data.errors;
  }

  public close() {
    this._modalRef.close(ModalOverlayServiceCloseEventType.CLOSE);
  }
}
