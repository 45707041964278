<div
  class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4 tw-items-center tw-py-2 tw-mb-4">
  <mg-text variant="header-md-secondary">
    {{ MESSAGES.HEADER }}
  </mg-text>
  <!-- TODO: check term quantity -->
  <mg-btn
    class="tw-grow sm:tw-grow-0 sm:tw-justify-self-end"
    variant="filled"
    (pressed)="openEditModal()">
    {{ MESSAGES.ADD_BTN }}
  </mg-btn>
</div>

<div
  *ngIf="(errors$ | async)?.length > 0 || (noTerms$ | async)"
  class="tw-flex tw-flex-column tw-flex-gap-4 tw-mb-4">
  <mg-system-alert
    *ngIf="noTerms$ | async"
    type="default"
    [message]="MESSAGES.NO_TERMS"
    [buttonText]="MESSAGES.NO_TERMS_BUTTON"
    [isAlert]="true"
    [dismissable]="true"
    [routerLink]="'/minga-manager/bell-schedule/terms'">
  </mg-system-alert>

  <mg-system-alert
    *ngIf="(errors$ | async)?.length > 0"
    type="error"
    [message]="MESSAGES.SCHEDULE_ERRORS"
    [buttonText]="MESSAGES.SCHEDULE_ERRORS_BUTTON"
    [isAlert]="true"
    [dismissable]="true"
    (dismissSnackbar)="showErrors()">
  </mg-system-alert>
</div>

<mat-table
  class="list-table"
  [dataSource]="dataSource"
  [trackBy]="trackById">
  <ng-container [matColumnDef]="COLUMN_DEF.NAME">
    <mat-header-cell
      *matHeaderCellDef
      class="w-lg-flex text-left">
      <mg-text
        variant="label-md-secondary"
        color="grey-300">
        {{ MESSAGES.COL_NAME }}
      </mg-text>
    </mat-header-cell>

    <mat-cell
      *matCellDef="let element"
      class="w-lg-flex text-left">
      <mg-text
        class="tw-block tw-w-full"
        variant="body-sm"
        [numberOfLines]="2">
        {{ element.name }}
      </mg-text>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_DEF.DEFAULT_DAYS">
    <mat-header-cell
      *matHeaderCellDef
      class="text-center">
      <mg-text
        variant="label-md-secondary"
        color="grey-300">
        {{ MESSAGES.COL_DEFAULT_DAYS }}
      </mg-text>
    </mat-header-cell>

    <mat-cell
      class="text-left"
      *matCellDef="let element">
      <mg-weekday-toggle
        class="tw-block"
        [readonly]="true"
        [initialValue]="element.days"></mg-weekday-toggle>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="COLUMN_DEF.TERMS">
    <mat-header-cell
      *matHeaderCellDef
      class="text-left">
      <mg-text
        variant="label-md-secondary"
        color="grey-300">
        {{ MESSAGES.COL_TERMS }}
      </mg-text>
    </mat-header-cell>

    <mat-cell
      class="text-left"
      *matCellDef="let element">
      <div
        *ngIf="element.terms.length > 0"
        class="tw-flex tw-flex-row tw-gap-1 tw-flex-wrap tw-w-full">
        <mg-tag
          *ngFor="let term of element.terms"
          color="blue">
          {{ term.title }}
        </mg-tag>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_DEF.DUPLICATE">
    <mat-header-cell
      *matHeaderCellDef
      class="w-10">
    </mat-header-cell>
    <mat-cell
      *matCellDef="let element"
      class="w-10 text-right">
      <mg-btn
        icon="mg-copy"
        variant="icon"
        iconSet="minga"
        (pressed)="copySchedule(element)">
      </mg-btn>
    </mat-cell>
  </ng-container>
  <ng-container [matColumnDef]="COLUMN_DEF.EDIT">
    <mat-header-cell
      *matHeaderCellDef
      class="w-10">
    </mat-header-cell>
    <mat-cell
      *matCellDef="let element"
      class="w-10 text-right">
      <mg-btn
        icon="mg-edit"
        variant="icon"
        iconSet="minga"
        (pressed)="openEditModal(element.id)">
      </mg-btn>
    </mat-cell>
  </ng-container>

  <ng-container [matColumnDef]="COLUMN_DEF.MOBILE">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell
      *matCellDef="let element; let i = index"
      class="mobile-layout">
      <ng-container
        [ngTemplateOutlet]="mobileListCardTemplate"
        [ngTemplateOutletContext]="{ $implicit: element, index: i }">
      </ng-container>
    </mat-cell>
  </ng-container>

  <mat-header-row
    *matHeaderRowDef="displayedColumns$ | async"
    [fxHide]="!dataSource?.data.length"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns$ | async"></mat-row>
</mat-table>
<!-- Loading Indicator Template -->
<ng-template #loadingTemplate>
  <!-- TODO lets get some placeholder stuff going -->
  <div class="tw-py-4">
    <mg-spinner
      [diameter]="40"
      [thickness]="3">
    </mg-spinner>
  </div>
</ng-template>

<!-- Empty State Template -->
<ng-template #noResultsTemplate>
  <mg-empty-state
    mg-stream-empty
    [title]="MESSAGES.NO_RESULTS_TITLE"
    [subtitle]="MESSAGES.NO_RESULTS_SUBTITLE">
  </mg-empty-state>
</ng-template>

<ng-template
  #mobileListCardTemplate
  let-element
  let-index="index">
  <mg-mobile-card [title]="element.name">
    <div
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="4px">
      <div
        fxLayout="row"
        fxLayoutAlign="center start">
        <mg-weekday-toggle
          class="tw-block tw-w-full tw-py-2"
          [readonly]="true"
          [initialValue]="element.days"></mg-weekday-toggle>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="4px">
        <mg-text
          class="tw-block w-14"
          variant="label-md"
          color="surface-alt">
          {{ MESSAGES.COL_TERMS }}
        </mg-text>
        <div
          *ngIf="element.terms.length > 0"
          class="tw-flex tw-flex-row tw-gap-1 tw-flex-wrap tw-w-full tw-justify-end">
          <mg-tag
            *ngFor="let term of element.terms"
            color="blue">
            {{ term.title }}
          </mg-tag>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-between start">
        <mg-btn
          icon="mg-copy"
          variant="icon"
          iconSet="minga"
          (pressed)="copySchedule(element.id)">
        </mg-btn>
        <mg-btn
          icon="mg-edit"
          variant="icon"
          iconSet="minga"
          (pressed)="openEditModal(element.id)">
        </mg-btn>
      </div>
    </div>
  </mg-mobile-card>
</ng-template>
