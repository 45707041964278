<div
  class="toggle-row"
  [class.has-secondary-label]="secondaryLabel">
  <div>
    <mg-text
      *ngIf="label"
      element="span"
      variant="body"
      [color]="toggleDisabled ? 'on-disabled-bg' : 'surface'">
      {{ label }}
    </mg-text>
    <mg-tooltip
      *ngIf="tooltip"
      [label]="tooltip">
    </mg-tooltip>
  </div>
  <mat-slide-toggle
    [formControl]="formControl"
    [class.no-label]="!label"
    [attr.data-analytics]="id"
    [attr.data-test]="id">
  </mat-slide-toggle>
</div>
<mg-text
  *ngIf="secondaryLabel"
  class="secondary-label"
  variant="body-xs"
  [color]="toggleDisabled ? 'on-disabled-bg' : 'surface-alt'"
  textAlign="left">
  {{ secondaryLabel }}
</mg-text>
