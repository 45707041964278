import * as day from 'dayjs';
import * as localeData from 'dayjs/plugin/localeData';
import { Observable, of } from 'rxjs';

import { Weekday } from './weekday-toggle.types';

day.extend(localeData);

/**
 * Get Name Of Weeks Days Observable
 */
export const getNameOfWeeksDaysObservable = (): Observable<Weekday[]> => {
  const weekdays = day.weekdays(true) as Weekday[];
  return of(weekdays);
};
