<!-- Container -->
<div
  #container
  class="range-picker">
  <div
    class="input-wrapper"
    [ngClass]="{
      focused: (inputIsFocused$ | async) !== null,
      error:
        rangeGroup.invalid &&
        rangeGroup.touched &&
        (inputIsFocused$ | async) === null,
      disabled: rangeGroup.disabled
    }">
    <button
      type="button"
      [disabled]="rangeGroup.disabled"
      class="icon-calendar"
      (click)="openCalendar()">
      <mg-icon>mg-calendar-today</mg-icon>
    </button>
    <input
      #startInput
      class="input-start"
      [placeholder]="startPlaceholder || 'Start date'"
      readonly="true"
      [ngClass]="{
        focused: (inputIsFocused$ | async) === 'start',
        disabled: rangeGroup.disabled
      }"
      [value]="
        rangeGroup.get('start').value
          ? rangeGroup.get('start').value.format(format)
          : ''
      "
      [attr.data-analytics]="id ? 'date-range-start-' + id : 'date-range-start'"
      [attr.data-test]="id ? 'date-range-start-' + id : 'date-range-start'" />
    <input
      #endInput
      class="input-end"
      [placeholder]="endPlaceholder || 'End date'"
      readonly="true"
      [ngClass]="{
        focused: (inputIsFocused$ | async) === 'end',
        disabled: rangeGroup.disabled
      }"
      [value]="
        rangeGroup.get('end').value
          ? rangeGroup.get('end').value.format(format)
          : ''
      "
      [attr.data-analytics]="id ? 'date-range-end-' + id : 'date-range-end'"
      [attr.data-test]="id ? 'date-range-end-' + id : 'date-range-end'" />
  </div>

  <ng-template #calendarOverlay>
    <div class="calendar-overlay"></div>
  </ng-template>
</div>
