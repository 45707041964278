import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import {
  BehaviorSubject,
  Observable,
  of,
  ReplaySubject,
  Subscription,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { PeopleFacadeService, Person } from 'src/app/people';

import { SelectElementOption } from '@shared/components/select-element';

import { FILTERS_FORM_PERSON_SEARCH_DEBOUNCE_TIME } from '../../constants';

@Component({
  selector: 'mg-filters-form-person-search',
  templateUrl: './filters-form-person-search.component.html',
  styleUrls: ['./filters-form-person-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersFormPersonSearchComponent implements OnInit, OnDestroy {
  // Cleanup
  private readonly _destroyedSubject = new ReplaySubject<void>(1);

  // Form
  public readonly searchControl = new FormControl('');

  // State
  private readonly _isLoadingSubject = new BehaviorSubject<boolean>(false);
  public readonly isLoading$ = this._isLoadingSubject.asObservable();

  // Search
  public readonly searchResults$ = this.searchControl.valueChanges
    .pipe(
      takeUntil(this._destroyedSubject),
      distinctUntilChanged(),
      tap(() => this._isLoadingSubject.next(true)),
      debounceTime(FILTERS_FORM_PERSON_SEARCH_DEBOUNCE_TIME),
      switchMap(searchQuery => {
        return this._personFacade.searchPeople(searchQuery).pipe(
          catchError(() => of([])),
          tap(() => this._isLoadingSubject.next(false)),
        );
      }),
    )
    .pipe(
      startWith([]),
      map((people: Person[]) =>
        people.map(person => ({
          value: person,
          label: `${person?.firstName} ${person?.lastName}`,
        })),
      ),
    );

  // Inputs
  @Input() label: string;
  @Input() id: string;
  @Input() multiple = true;
  @Input() done: Observable<any>;
  @Input() clearSelection: Observable<void>;
  @Input() set selected(value: Person[]) {
    this._loadSelectedOptions(value);
  }

  // Outputs
  @Output() selectionChange = new EventEmitter<SelectElementOption[]>();

  // Selection
  public readonly selection = new SelectionModel<SelectElementOption>(
    this.multiple,
  );

  // Subscriptions
  private _doneSubscription: Subscription;
  private _clearSelectionSubscription: Subscription;

  constructor(private _personFacade: PeopleFacadeService) {}

  ngOnInit(): void {
    this._doneSubscription = this.done?.subscribe(() => {
      this.selectionChange.emit(this.selection.selected);
    });

    this._clearSelectionSubscription = this.clearSelection?.subscribe(() => {
      this.selection.clear();
      this.selectionChange.emit(null);
    });
  }

  ngOnDestroy(): void {
    this._destroyedSubject.next();
    this._destroyedSubject.complete();
    this._doneSubscription?.unsubscribe();
    this._clearSelectionSubscription?.unsubscribe();
  }

  public select(option: SelectElementOption) {
    this.selection.toggle(option);
  }

  public getDisplayedOptions(results?: SelectElementOption[]) {
    const options: SelectElementOption[] = [];
    const selectedHashes = new Set();

    for (const option of this.selection.selected) {
      selectedHashes.add(option.value.hash);
      options.push(option);
    }

    for (const option of results || []) {
      if (selectedHashes.has(option.value.hash)) continue;
      options.push(option);
    }

    return options;
  }

  /**
   * Loads selected options to display
   *
   * @param value - Selected values. We get a hash array from the table and a person array from the filters form.
   */
  private async _loadSelectedOptions(value: Person[]) {
    for (const v of value) {
      this.select({
        value: v,
        label: `${v.firstName} ${v.lastName}`,
      });
    }
  }
}
