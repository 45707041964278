import { Observable } from 'rxjs';

import { IMembershipList } from 'minga/domain/membershipList';
import { Group } from 'src/app/groups/models';
import { MgIcons } from 'src/app/icon/mg-icons.types';

import { ToolType } from '@modules/tools/tools.types';

export type ToolsGroupsModalData = {
  personHash: string;
  groups$: Observable<Group[]>;
};

export type ToolsStickersModalData = {
  personHash: string;
  stickers: IMembershipList[];
};

export enum ACTION_CATEGORY {
  HALL_PASS = 'hall_pass',
  CHECK_IN = 'check_in',
  ACTIVITY = 'activity',
  PBIS = 'pbis',
  POINTS = 'points',
  KIOSK = 'kiosk',
}

export enum ACTION_KEY {
  CREATE_HALL_PASS = 'create_hall_pass',
  CHECK_IN = 'check_in',
  SELF_CHECK_IN = 'self_check_in',
  ASSIGN_ACTIVITY = 'assign_activity',
  ASSIGN_PRAISE = 'assign_praise',
  ASSIGN_GUIDANCE = 'assign_guidance',
  ASSIGN_CONSEQUENCE = 'assign_consequence',
  REDEEM_POINTS = 'redeem_points',
  MANAGE_POINTS = 'manage_points',
  KIOSK_MODE = 'kiosk_mode',
}

export type ToolAction = {
  key: ACTION_KEY;
  label: string;
  icon: MgIcons;
  iconColor: string;
  onPress: () => void;
  hasPermission$: Observable<boolean>;
  category: ACTION_CATEGORY;
  usedIn: ToolType[];
  id: string; // used for analytics
};
