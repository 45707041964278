import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FormModule } from '@shared/components/form';
import { SystemAlertModule } from '@shared/components/system-alert';
import { WeekdayToggleModule } from '@shared/components/weekday-toggle';
import { CommonUIModules } from '@shared/modules';
import { BellSchedulesInterface } from '@shared/services/bell-schedule/bs-schedules.interface';
import { BsSchedulesService } from '@shared/services/bell-schedule/bs-schedules.service';
import { BellScheduleTermsInterface } from '@shared/services/bell-schedule/bs-terms.interface';
import { BsTermsService } from '@shared/services/bell-schedule/bs-terms.service';

import { MmBsCalendarComponent } from './components/mm-bs-calendar/mm-bs-calendar.component';
import { MmBsErrorsComponent } from './components/mm-bs-errors/mm-bs-errors.component';
import { MmBsSchedulesEditComponent } from './components/mm-bs-schedules-edit/mm-bs-schedules-edit.component';
import { MmBsSchedulesComponent } from './components/mm-bs-schedules/mm-bs-schedules.component';
import { MmBsTermsEditComponent } from './components/mm-bs-terms-edit/mm-bs-terms-edit.component';
import { MmBsTermsComponent } from './components/mm-bs-terms/mm-bs-terms.component';
import { MmBellScheduleComponent } from './mm-bell-schedule.component';
import { OverrideOptionsPipe } from './pipes/overrideOptions.pipe';

@NgModule({
  declarations: [
    MmBellScheduleComponent,
    MmBsTermsComponent,
    MmBsSchedulesComponent,
    MmBsCalendarComponent,
    MmBsTermsEditComponent,
    MmBsSchedulesEditComponent,
    MmBsErrorsComponent,
    OverrideOptionsPipe,
  ],
  imports: [
    CommonModule,
    CommonUIModules,
    WeekdayToggleModule,
    RouterModule,
    ReactiveFormsModule,
    FormModule,
    SystemAlertModule,
  ],
  providers: [
    {
      provide: BellSchedulesInterface,
      useClass: BsSchedulesService,
    },
    {
      provide: BellScheduleTermsInterface,
      useClass: BsTermsService,
    },
  ],
})
export class MmBellScheduleModule {}
