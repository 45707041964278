<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [overlayTitle]="MESSAGES.TITLE">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Error Template -->
<ng-template #mainTemplate>
  <main class="modal-form">
    <!-- TODO: fix horizontal dividers: tw-divide-y tw-divide-gray-300 -->
    <ul class="tw-list-none tw-pl-0 tw-m-0">
      <li
        *ngFor="let error of errors"
        class="tw-p-3">
        {{ error }}
      </li>
    </ul>
  </main>
</ng-template>

<!-- Footer Template -->
<ng-template #footerTemplate>
  <footer class="tw-flex tw-items-center tw-justify-end">
    <mg-btn
      attrType="button"
      variant="filled"
      (pressed)="close()">
      {{ MESSAGES.CLOSE_BUTTON }}
    </mg-btn>
  </footer>
</ng-template>
