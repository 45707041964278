import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { SCHEDULES_MESSAGES } from './constants/mm-bs-schedules.constants';

@Component({
  selector: 'mg-mm-bell-schedule',
  templateUrl: './mm-bell-schedule.component.html',
  styleUrls: ['./mm-bell-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmBellScheduleComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
