<section class="tw-grid tw-grid-cols-1 tw-row-gap-4 tw-column-gap-2">
  <mg-tools-actions-header
    *ngIf="person$ | async; else headerLoader"
    [person]="person$ | async"
    [activeHallPass]="activeHallPass$ | async"
    [onOwnProfile]="true">
    <mg-btn
      *ngIf="hallPassCreateAction?.hasPermission$ | async"
      variant="outlined"
      icon="mg-hallpass-menu"
      iconVariant="raised-accent"
      [iconColor]="ICON_COLORS.hallPass"
      iconSet="minga"
      (pressed)="hallPassCreateAction.onPress()">
      {{ MESSAGES.HALL_PASS_CREATE }}
    </mg-btn>
    <mg-btn
      *ngIf="selfCheckInAction?.hasPermission$ | async"
      variant="outlined"
      icon="mg-checkin-menu"
      iconVariant="raised-accent"
      [iconColor]="ICON_COLORS.checkIn"
      iconSet="minga"
      (pressed)="selfCheckInAction.onPress()">
      {{ selfCheckInAction.label }}
    </mg-btn>
    <ng-container *ngIf="hasAssignActions$ | async">
      <mg-btn
        class="dropdown-btn"
        [matMenuTriggerFor]="assignDropdown"
        variant="outlined"
        icon="mg-assign"
        iconVariant="raised-accent"
        [iconColor]="ICON_COLORS.assign"
        iconSet="minga"
        iconRight="mg-drop-down-arrow">
        {{ MESSAGES.ASSIGN_BTN }}
      </mg-btn>
      <mat-menu #assignDropdown="matMenu">
        <ng-container *ngFor="let option of assignActions">
          <button
            *ngIf="option.hasPermission$ | async"
            mat-menu-item
            class="action-dropdown"
            variant="outlined"
            (click)="option.onPress()">
            <mg-icon
              [iconName]="option.icon"
              [iconColor]="option.iconColor"
              variant="raised-accent"></mg-icon>
            <mg-text variant="body-md">{{ option.label }} </mg-text>
          </button>
        </ng-container>
      </mat-menu>
    </ng-container>
  </mg-tools-actions-header>

  <!--
    The order of the following content is handled by css by appending
    different classes to the appropriate templates. Many of these template
    blocks have complimentary css rules to achieve the grid layout;
    be mindful of the other blocks if you change a css grid rule.
  -->
  <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-2">
    <ng-container *ngTemplateOutlet="metricsCardsTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="todaysActivitiesTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="myStickersTemplate"></ng-container>
    <ng-container *ngTemplateOutlet="myGroupsTemplate"></ng-container>
  </div>
</section>

<!--
  Metrics Template
  =============================================================================
  There are a mix of grid and flex rules here, so be careful which ones you
  are altering. Grid rules are for managing placement of the metric card
  template in the page layout relative to other templates such as
  todaysActivities, myStickers… etc. The flex rules are used for internally
  ordering and expanding the metric cards to properly fill the space they are
  given.
-->
<ng-template #metricsCardsTemplate>
  <ng-container *ngIf="totalStatsCount$ | async as totalStats">
    <div
      *ngIf="totalStats > 0"
      class="tw-flex tw-gap-2 tw-mb-2 tw-flex-wrap sm:tw-flex-nowrap"
      [class.md:tw-col-span-2]="totalStats > 1"
      [class.md:tw-order-2]="
        totalStats === 1 && (isScheduleEnabled$ | async) === true
      "
      [class.md:tw-col-start-2]="
        totalStats === 1 && (isScheduleEnabled$ | async) === false
      ">
      <ng-container
        *ngIf="
          (loadingStats$ | async) === false && userStats$ | async as stats
        ">
        <mg-tools-metrics-card
          *ngIf="isBehaviorEnabled$ | async"
          class="tw-basis-full tw-min-[320px]:tw-basis-1/2 sm:tw-basis-full tw-shrink tw-grow"
          [metric]="'redeem-points'"
          [id]="'tools-dashboard-click-metric-redeemable-points'"
          [value]="stats?.redeemablePointCount | zeroIfNegative"
          (pressed)="
            handleStatNavigate([
              TOOLS_ROUTES.ROOT,
              TOOLS_ROUTES.POINTS,
              TOOLS_ROUTES.YOUR_POINTS
            ])
          ">
        </mg-tools-metrics-card>
        <mg-tools-metrics-card
          *ngIf="isConsequencesEnabled$ | async"
          class="tw-basis-full tw-min-[320px]:tw-basis-1/2 sm:tw-basis-full tw-shrink tw-grow"
          [metric]="'overdue-consequence'"
          [id]="'tools-dashboard-click-metric-overdue-consequences'"
          [value]="stats?.overdueConsequenceCount"
          (pressed)="
            handleStatNavigate([TOOLS_ROUTES.ROOT, TOOLS_ROUTES.BEHAVIOURS])
          ">
        </mg-tools-metrics-card>
        <mg-tools-metrics-card
          *ngIf="isConsequencesEnabled$ | async"
          class="tw-basis-full tw-min-[320px]:tw-basis-1/2 sm:tw-basis-full tw-shrink tw-grow"
          [metric]="'active-consequence'"
          [id]="'tools-dashboard-click-metric-active-consequences'"
          [value]="stats?.activeConsequenceCount"
          (pressed)="
            handleStatNavigate([TOOLS_ROUTES.ROOT, TOOLS_ROUTES.BEHAVIOURS])
          ">
        </mg-tools-metrics-card>
        <mg-tools-metrics-card
          *ngIf="isFlexEnabled$ | async"
          class="tw-basis-full tw-min-[320px]:tw-basis-1/2 sm:tw-basis-full tw-shrink tw-grow"
          [metric]="'unregistered-periods'"
          [id]="'tools-dashboard-click-metric-unregistered-periods'"
          [value]="stats?.unregisteredPeriodCount"
          (pressed)="
            handleStatNavigate([TOOLS_ROUTES.ROOT, TOOLS_ROUTES.FLEX_TIME])
          ">
        </mg-tools-metrics-card>
      </ng-container>

      <!-- Loading Metric Cards Placeholder -->
      <ng-container *ngIf="(loadingStats$ | async) === true">
        <mg-placeholder-card
          *ngFor="let index of [].constructor(totalStats); let i = index"
          class="tw-basis-full tw-min-[320px]:tw-basis-1/2 sm:tw-basis-full tw-shrink tw-grow">
          <div class="tw-grid tw-gap-2.5">
            <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
              <mg-placeholder-avatar size="small"></mg-placeholder-avatar>
              <mg-placeholder-header
                class="tw-w-2/3"
                size="small"></mg-placeholder-header>
            </div>
            <mg-placeholder-button class="tw-w-1/2"></mg-placeholder-button>
          </div>
        </mg-placeholder-card>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<!-- Today's Activities -->
<ng-template #todaysActivitiesTemplate>
  <div
    *ngIf="isScheduleEnabled$ | async"
    class="md:tw-row-span-3">
    <mg-student-schedule-widget
      *ngIf="(loadingSchedule$ | async) === false; else loadingActivities"
      [scheduleItems]="scheduleItems$ | async"
      (viewAllPressed)="goToSchedule($event)"></mg-student-schedule-widget>
  </div>
</ng-template>

<!-- My Stickers -->
<ng-template #myStickersTemplate>
  <mg-tools-stickers
    [class.md:tw-order-2]="
      (totalStatsCount$ | async) === 1 && (isScheduleEnabled$ | async) === true
    "
    [class.md:tw-row-start-1]="
      (totalStatsCount$ | async) === 1 && (isScheduleEnabled$ | async) === false
    "
    [class.md:tw-row-end-3]="
      (totalStatsCount$ | async) === 1 && (isScheduleEnabled$ | async) === false
    "
    [personHash]="(person$ | async)?.hash">
  </mg-tools-stickers>
</ng-template>

<!-- My Groups -->
<ng-template #myGroupsTemplate>
  <mg-tools-groups
    *ngIf="isGroupsEnabled$ | async"
    [class.md:tw-order-2]="
      (totalStatsCount$ | async) === 1 && (isScheduleEnabled$ | async) === true
    "
    [class.md:tw-col-start-2]="
      (totalStatsCount$ | async) === 1 && (isScheduleEnabled$ | async) === false
    "
    [personHash]="(person$ | async)?.hash">
  </mg-tools-groups>
</ng-template>

<!-- Loading Header Placeholder -->
<ng-template #headerLoader>
  <mg-placeholder-card>
    <div class="tw-grid tw-gap-2.5">
      <div class="tw-flex tw-flex-row tw-gap-2 tw-items-center">
        <mg-placeholder-avatar size="medium"></mg-placeholder-avatar>
        <mg-placeholder-header
          class="tw-w-2/3"
          size="medium"></mg-placeholder-header>
      </div>
      <mg-placeholder-button class="tw-w-1/3"></mg-placeholder-button>
    </div>
  </mg-placeholder-card>
</ng-template>

<!-- Loading Activities Placeholder, TODO: update this to use _tailwind.css classes -->
<ng-template #loadingActivities>
  <mg-placeholder-card>
    <div
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="8px">
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center">
        <mg-placeholder-header
          class="w-32"
          size="small"></mg-placeholder-header>
        <mg-placeholder-button class="w-20"></mg-placeholder-button>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="4px">
        <mg-placeholder-avatar size="medium"></mg-placeholder-avatar>
        <div
          fxLayout="column"
          fxLayoutGap="4px"
          fxFlex>
          <mg-placeholder-header
            size="small"
            class="w-1-3"></mg-placeholder-header>
          <mg-placeholder-text
            size="small "
            class="w-1-2"></mg-placeholder-text>
        </div>
      </div>
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        fxLayoutGap="4px">
        <mg-placeholder-avatar size="medium"></mg-placeholder-avatar>
        <div
          fxLayout="column"
          fxLayoutGap="4px"
          fxFlex>
          <mg-placeholder-header
            size="small"
            class="w-1-3"></mg-placeholder-header>
          <mg-placeholder-text
            size="small "
            class="w-1-2"></mg-placeholder-text>
        </div>
      </div>
    </div>
  </mg-placeholder-card>
</ng-template>
