import { Injectable } from '@angular/core';

import { Period } from 'minga/domain/period';
import { PeriodManager } from 'minga/proto/period/period_ng_grpc_pb';
import { GetPeriodsRequest } from 'minga/proto/period/period_pb';
import { PeriodMapper } from 'minga/shared-grpc/period';

import { CacheService } from '../cache/cache.service';
import { CacheKey } from '../cache/cache.types';
import { ErrorHandlerService } from '../error-handler';

@Injectable({ providedIn: 'root' })
export class BsPeriodsService {
  private _cachedPeriods = this._cacheService.create<Period[]>(
    CacheKey.BELL_SCHEDULE_PERIODS_LIST,
    data => {
      return this._fetchAll();
    },
    {
      ttl: 60,
    },
  );
  constructor(
    private _cacheService: CacheService,
    private _errorHandler: ErrorHandlerService,
    private _periodManager: PeriodManager,
  ) {}

  public async fetchAll(opts?: { revalidate?: boolean }) {
    return await this._cachedPeriods.get({}, opts).toPromise();
  }

  private async _fetchAll(): Promise<Period[]> {
    try {
      const request = new GetPeriodsRequest();

      const result = await this._periodManager.getPeriods(request);
      const periods = result.getPeriodsList();
      const mapped = periods.map(PeriodMapper.fromProto);
      return mapped;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to fetch terms',
        error,
        true,
        true,
      );
    }
  }
}
