import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

import dayjs from 'dayjs';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { $enum } from 'ts-enum-util';

import { IBellSchedule } from 'minga/domain/bellSchedule';
import { day } from 'minga/shared/day';
import { RootService } from 'src/app/minimal/services/RootService';

import { FormSelectOption } from '@shared/components/form';
import { SystemAlertSnackBarService } from '@shared/components/system-alert-snackbar';
import { Weekday } from '@shared/components/weekday-toggle';
import {
  BellSchedulesInterface,
  BellSchedulesService,
} from '@shared/services/bell-schedule/bs-schedules.interface';
import {
  BellScheduleTermsInterface,
  BellScheduleTermsService,
} from '@shared/services/bell-schedule/bs-terms.interface';

import {
  BS_CALENDAR_COLUMN_LABELS,
  BS_CALENDAR_COLUMNS,
  BS_CALENDAR_MESSAGES,
  BSCalendarColumns,
} from '../../constants/mm-bs-calendar.constants';
import { ClientTerm } from '../../types/mm-bell-schedule.types';

/** Just local convenience types for the table data. */
export type TableSchedule = {
  date: day.Dayjs;
  bellId?: number;
  // keep track of both of these in the table data so we can expose a way to clear the override
  overrideBellId?: number;
  state?: State;
};
type State = 'none' | 'default' | 'custom';

@Component({
  selector: 'mg-mm-bs-calendar',
  templateUrl: './mm-bs-calendar.component.html',
  styleUrls: ['./mm-bs-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

//
export class MmBsCalendarComponent implements OnInit, OnDestroy {
  /** Constants */
  public readonly MESSAGES = BS_CALENDAR_MESSAGES;

  /** Table */
  public readonly TABLE_COLUMN = BS_CALENDAR_COLUMNS;
  public readonly TABLE_COLUMN_LABEL = BS_CALENDAR_COLUMN_LABELS;
  public readonly tableColumns = BSCalendarColumns;
  public readonly tableDataSource = new MatTableDataSource<TableSchedule>([]);

  /** Data */
  private _errors: string[] = [];

  /** Subjects */
  private _destroyed$ = new ReplaySubject<void>(1);
  private _currentTermSubject = new BehaviorSubject<ClientTerm>(undefined);
  private _schedulesSubject = new BehaviorSubject<IBellSchedule[]>([]);
  private _termsSubject = new BehaviorSubject<ClientTerm[]>([]);
  private readonly _isLoadingSubject = new BehaviorSubject(true);

  /** Observables */
  public readonly currentTerm$ = this._currentTermSubject.asObservable();
  public readonly isLoading$ = this._isLoadingSubject.asObservable();

  public readonly termsOptions$: Observable<FormSelectOption[]> =
    this._termsSubject.pipe(
      takeUntil(this._destroyed$),
      map(terms => {
        return terms.map(t => ({
          value: t.id,
          label: t.title,
          term: t,
        }));
      }),
    );

  private _schedulesByTerm$: Observable<IBellSchedule[]> = combineLatest([
    this._schedulesSubject,
    this._currentTermSubject,
  ]).pipe(
    filter(([schedules, currentTerm]) => !!currentTerm?.id),
    map(([schedules, currentTerm]) => {
      return schedules.filter(schedule => {
        return schedule.terms.some(t => t.id === currentTerm.id);
      });
    }),
  );

  public readonly scheduleOptions$: Observable<FormSelectOption[]> =
    this._schedulesByTerm$.pipe(
      takeUntil(this._destroyed$),
      map(schedules => {
        return schedules.map(s => ({ value: s.id, label: s.name }));
      }),
    );

  /**
   * If you want to change the wiring for the services provided, see
   * mm-bell-schedule.module.ts
   */
  constructor(
    private _systemAlertSnackBar: SystemAlertSnackBarService,
    @Inject(BellSchedulesInterface)
    private _schedulesService: BellSchedulesService,
    @Inject(BellScheduleTermsInterface)
    private _termsService: BellScheduleTermsService,
    private _rootService: RootService,
  ) {
    /** Generate table data whenever the current term changes. */
    combineLatest([this.currentTerm$, this._schedulesByTerm$])
      .pipe(
        takeUntil(this._destroyed$),
        filter(([term]) => !!term?.id),
      )
      .subscribe(([term, schedulesByTerm]) => {
        // Reset errors for the selected term.
        this._errors = [];

        this.tableDataSource.data = this._generateTableData(
          term,
          schedulesByTerm,
        );
      });
  }

  ngOnInit(): void {
    this._loadInitialData();
  }

  private async _loadInitialData(): Promise<void> {
    this._isLoadingSubject.next(true);

    try {
      const [terms, schedules] = await Promise.all([
        this._termsService.fetchAll(),
        this._schedulesService.fetchAll(),
      ]);

      this._termsSubject.next(terms);
      this._schedulesSubject.next(schedules);

      if (terms.length > 0) {
        this._currentTermSubject.next(terms[0]);
      }
    } catch (error) {
      this._systemAlertSnackBar.error(this.MESSAGES.ERROR_LOADING_DATA);
    } finally {
      this._isLoadingSubject.next(false);
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  public tooltipForState(state?: State): string {
    if (state === 'default') {
      return BS_CALENDAR_MESSAGES.ASSIGNED_DEFAULT;
    } else if (state === 'custom') {
      return BS_CALENDAR_MESSAGES.ASSIGNED_CUSTOM;
    } else {
      return BS_CALENDAR_MESSAGES.ASSIGNED_NONE;
    }
  }

  public iconForState(state?: State): string {
    if (state === 'custom') {
      return 'mg-custom-schedule';
    } else {
      return 'mg-check-fill';
    }
  }

  public changedSelectedTerm(val) {
    const { term } = val;
    this._currentTermSubject.next(term);
  }

  public async changedBellSchedule(
    scheduleId: number | null,
    tableSchedule: TableSchedule,
  ) {
    const { date, overrideBellId } = tableSchedule;
    await this._rootService.addLoadingPromise(
      this._schedulesService.overrideScheduleForDate(scheduleId, date),
    );

    // if we're changing/adding an override schedule, we need to update the schedule
    const newOverrideSchedule = this._schedulesSubject.value.find(
      s => s.id === scheduleId,
    );

    // if there was a previous override schedule, we need to remove the date from it
    const previouseOverrideSchedule = this._schedulesSubject.value.find(
      s => s.id === overrideBellId,
    );

    let updated = null;

    if (newOverrideSchedule) {
      updated = await this._schedulesService.updateListCache({
        action: 'update',
        data: {
          ...newOverrideSchedule,
          dates: [...(newOverrideSchedule.dates || []), date],
        },
      });
    }

    if (previouseOverrideSchedule) {
      updated = await this._schedulesService.updateListCache({
        action: 'update',
        data: {
          ...previouseOverrideSchedule,
          dates: previouseOverrideSchedule.dates.filter(
            d => !day(d).isSame(date, 'day'),
          ),
        },
      });
    }

    // if we updated the cache lets trigger a re-render of the table
    if (updated) {
      this._schedulesSubject.next(updated);
    }

    this._systemAlertSnackBar.success(BS_CALENDAR_MESSAGES.SCHEDULE_CHANGED);
  }

  public hasErrors(): boolean {
    return this._errors.length > 0;
  }

  public hasData(): boolean {
    return (
      this._schedulesSubject.value.length > 0 &&
      this._termsSubject.value.length > 0
    );
  }

  /**
   * Generate the schedule data for the table from the term and bell schedules.
   */
  private _generateTableData(
    term: ClientTerm,
    termSchedules: IBellSchedule[],
    minDays: number = 1,
    maxDays: number = 365,
  ): TableSchedule[] {
    /**
     * Get the day count between the term dates. Diff from the end to the
     * start for a positive day count.
     */
    const getDayCount = (start: day.Dayjs, end: day.Dayjs): number => {
      const days = end.diff(start, 'days');

      if (days < minDays) {
        return 0;
      } else if (days > maxDays) {
        return maxDays;
      } else {
        // +1 to include the end day of the range.
        return days + 1;
      }
    };

    /**
     * For readability only generating the dates here, merging the
     * dates and schedules in another step.
     */
    const makeDatesWithinRange = (start: Date, end: Date): day.Dayjs[] => {
      const startDate = day(start);
      const endDate = day(end);
      const dayCount = getDayCount(startDate, endDate);

      // Set errors for day count lengths, if any.
      if (dayCount < minDays) {
        this._errors.push('The term length must be at least one day.');
      } else if (dayCount > maxDays) {
        this._errors.push(
          `The term (${term.title}) length must be less or equal to one year (${maxDays} days).`,
        );
      }

      return Array.from({ length: dayCount }, (_, i) => {
        return startDate.add(i, 'days').startOf('day');
      });
    };

    if (term.startDate && term.endDate) {
      const dates = makeDatesWithinRange(term.startDate, term.endDate);

      /** Convert the date, term and schedules to table data type. */
      const convertToTableData = (date: dayjs.Dayjs): TableSchedule => {
        const dayOfWeek = date.day();

        /** Get the weekday for the current date. */
        const weekday = $enum(Weekday)
          .getValues()
          .find((_value, index) => dayOfWeek === index);

        /** Get the selected bell schedule for the current weekday */
        const selectedSchedule = termSchedules.find(schedule =>
          schedule.days.includes(weekday),
        );

        const overrides = termSchedules.filter(s => {
          return (s.dates || []).some(d => day(d).isSame(date, 'day'));
        });

        const overrideBellId = overrides?.[0]?.id;

        const bellId = selectedSchedule?.id;
        const state = overrideBellId ? 'custom' : bellId ? 'default' : 'none';

        return {
          date,
          state,
          overrideBellId,
          ...(bellId ? { bellId } : {}),
        };
      };

      return dates.map(convertToTableData);
    } else {
      this._errors.push(`${term.title} must have a start and end date.`);
      return [];
    }
  }
}
