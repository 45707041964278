import { Injectable } from '@angular/core';

import * as day from 'dayjs';

import * as flex_time_pb from 'minga/proto/flex_time/flex_time_pb';
import { CheckinReasonType, ICheckinReason } from 'minga/domain/checkin';
import {
  FlexTimeActivityInstance,
  FlexTimeActivityInstancePayload,
} from 'minga/domain/flexTime';
import { FlexTimeManager } from 'minga/proto/flex_time/flex_time_ng_grpc_pb';
import { FlexTimeActivityInstanceMapper } from 'minga/shared-grpc/flex_time';
import { dateTimeObjectToDateTimeMessage } from 'src/app/util/date';

import { FtmActivitySelectorModalComponent } from '@modules/flex-time-manager/components/ftm-shared/components/ftm-activity-selector-modal/ftm-activity-selector-modal';
import {
  ActivitySelectorModalData,
  ActivitySelectorModalResponse,
} from '@modules/flex-time-manager/components/ftm-shared/ftm-shared.constants';

import {
  ModalOverlayService,
  ModalOverlayServiceCloseEventType,
} from '@shared/components/modal-overlay';
import { getAppColor } from '@shared/constants';
import { ErrorHandlerService } from '@shared/services/error-handler';

@Injectable()
export class FlexTimeActivityInstanceService {
  /** Service Constructor */
  constructor(
    private _flexTimeManager: FlexTimeManager,
    private _errorHandler: ErrorHandlerService,
    private _modalService: ModalOverlayService<
      ActivitySelectorModalResponse,
      ActivitySelectorModalData
    >,
  ) {}

  public async fetchAll({
    startDate,
    endDate,
    periodId,
    personHashes,
    activityTemplateId,
  }: {
    startDate?: day.Dayjs;
    endDate?: day.Dayjs;
    periodId?: number;
    personHashes?: string[];
    activityTemplateId?: number;
  }): Promise<FlexTimeActivityInstance[]> {
    try {
      const request = new flex_time_pb.ListFlexTimeActivityInstancesRequest();

      if (startDate) {
        const startDateTime = dateTimeObjectToDateTimeMessage(
          startDate.startOf('day').toDate(),
        );
        request.setStartDate(startDateTime);
      }

      if (endDate) {
        const endDateTime = dateTimeObjectToDateTimeMessage(
          endDate.startOf('day').toDate(),
        );
        request.setEndDate(endDateTime);
      }

      if (periodId) request.setPeriodId(periodId);

      if (personHashes?.length) request.setPersonHashesList(personHashes);

      if (activityTemplateId) request.setActivityTemplateId(activityTemplateId);

      const response =
        await this._flexTimeManager.listFlexTimeActivityInstances(request);
      return response
        .getFlexTimeActivityInstancesList()
        .map(FlexTimeActivityInstanceMapper.fromProto);
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'failed to fetch time activities instances',
        error,
        true,
      );
    }
  }

  public async create(
    activityInstance: FlexTimeActivityInstancePayload,
  ): Promise<FlexTimeActivityInstance> {
    try {
      const request = new flex_time_pb.UpsertFlexTimeActivityInstanceRequest();
      const instance = new flex_time_pb.FlexTimeActivityInstance();
      instance.setActivityId(activityInstance.flexTimeActivityId);
      instance.setPeriodId(activityInstance.flexTimePeriodId);
      request.setFlexTimeActivityInstancesList([instance]);
      const response =
        await this._flexTimeManager.upsertFlexTimeActivityInstance(request);
      return FlexTimeActivityInstanceMapper.fromProto(
        response.getFlexTimeActivityInstancesList()[0],
      );
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to create FlexTime activity instance',
        error,
        true,
      );
    }
  }

  public async update(): Promise<FlexTimeActivityInstance> {
    try {
      return null;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to delete FlexTime activity instance',
        error,
        true,
      );
    }
  }

  public async delete(id: number): Promise<boolean> {
    try {
      const request = new flex_time_pb.DeleteFlexTimeActivityInstanceRequest();
      request.setId(id);
      await this._flexTimeManager.deleteFlexTimeActivityInstance(request);
      return true;
    } catch (error) {
      throw this._errorHandler.gateWayError(
        'Failed to delete FlexTime activity instance',
        error,
        true,
      );
    }
  }

  public async openActivitySelector(
    modalData: ActivitySelectorModalData,
  ): Promise<FlexTimeActivityInstance> {
    return new Promise((resolve, reject) => {
      const modalRef = this._modalService.open(
        FtmActivitySelectorModalComponent,
        {
          data: modalData || {},
        },
      );

      modalRef.afterClosed.subscribe(async response => {
        const { type, data } = response;

        if (
          type === ModalOverlayServiceCloseEventType.CREATE &&
          data.activityInstance
        ) {
          resolve(data.activityInstance);
        }
      });
    });
  }

  public mapFlexActivityInstanceToReason(
    activity: FlexTimeActivityInstance,
  ): ICheckinReason {
    return {
      id: activity.checkinReasonId,
      name: activity?.flexTimeActivity?.name || '',
      icon: 'FlexTime-o',
      mingaId: activity.mingaId,
      color: getAppColor('flextime'),
      active: false,
      checkinReasonType: CheckinReasonType.FLEXTIME,
      pointReward: 0,
      showAbsentees: false,
      selfCheckIn: false,
    };
  }

  public getTotalCounts(instances: FlexTimeActivityInstance[]): {
    totalRegistered: number;
    totalSeats: number;
    totalAbsentees: number;
  } {
    const [totalRegistered, totalSeats, totalAbsentees] = (
      instances || []
    ).reduce(
      (totals, curr) => {
        const [registered, seats, absentees] = totals;
        return [
          registered + curr.registered,
          seats + curr.spaces,
          absentees + curr.absentees,
        ];
      },
      [0, 0, 0],
    );

    return {
      totalRegistered,
      totalSeats,
      totalAbsentees,
    };
  }
}
