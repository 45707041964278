<!-- Main Overlay -->
<mg-overlay-primary
  size="auto"
  [isLoading]="(formState$ | async) === 'loading' || (loadingData$ | async)"
  [overlayTitle]="
    (isNew$ | async) ? MESSAGES.MODAL_TITLE_ADD : MESSAGES.MODAL_TITLE_EDIT
  ">
  <ng-container *ngTemplateOutlet="mainTemplate"></ng-container>
  <ng-template #overlayFooter>
    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
  </ng-template>
</mg-overlay-primary>

<!-- Main Content Template -->
<ng-template #mainTemplate>
  <main
    class="modal-form"
    #crudForm>
    <form
      id="bs-terms-form"
      [formGroup]="form"
      (ngSubmit)="submit()">
      <div
        fxLayout="column"
        fxLayoutAlign="start stretch"
        fxLayoutGap="24px">
        <fieldset *ngIf="(formState$ | async) === 'invalid'">
          <mg-system-alert
            type="error"
            [message]="MESSAGES.ERROR_GENERIC"></mg-system-alert>
        </fieldset>
        <fieldset>
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="12px">
            <div
              fxLayout="row"
              fxLayoutGap="12px">
              <div class="short-code">
                <!------- We only support SIS synced terms atm, so this disable certain fields from being editable ------->
                <mg-form-select
                  [isFullWidth]="true"
                  [hint]="
                    (isSisSynced$ | async)
                      ? MESSAGES.FIELD_LABEL_SHORT_CODE_HINT
                      : ''
                  "
                  [placeholder]="MESSAGES.FIELD_LABEL_SHORT_CODE"
                  [options]="termsOptions$ | async"
                  [control]="form.get(FORM_FIELDS.SHORT_CODE)"
                  [disabled]="true">
                </mg-form-select>
              </div>
              <div fxFlex="grow">
                <mg-form-text-input
                  [label]="MESSAGES.FIELD_LABEL_NAME"
                  [control]="form.get(FORM_FIELDS.TITLE)">
                </mg-form-text-input>
              </div>
            </div>
            <div
              fxLayout="column"
              fxLayoutAlign="start stretch"
              fxLayoutGap="4px">
              <!------- We only support SIS synced terms atm, so this disable certain fields from being editable ------->
              <mg-form-date-range
                [disabled]="true"
                [range]="form.get(FORM_FIELDS.DATE_RANGE)">
              </mg-form-date-range>
              <mg-form-error *ngIf="form.getError('overlappingTerms')">
                {{ MESSAGES.ERROR_NO_OVERLAPPING_TERMS_DATE_RANGE_ERROR }}
              </mg-form-error>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>
            <div
              fxLayout="row"
              fxLayoutAlign="start center">
              <mg-text variant="header-sm-secondary">
                {{ MESSAGES.FIELDSET_SCHEDULE_TITLE }}
              </mg-text>
              <!-- <mg-tooltip [label]="MESSAGES.FIELDSET_TERMS_TOOLTIP">
              </mg-tooltip> -->
            </div>

            <mg-text
              variant="body-xs"
              color="surface-alt"
              textAlign="left">
              {{ MESSAGES.FIELDSET_SCHEDULE_DESC }}
            </mg-text>
          </legend>
          <div
            fxLayout="column"
            fxLayoutAlign="start stretch"
            fxLayoutGap="4px">
            <mg-form-select
              appendTo="body"
              [multiple]="true"
              [isFullWidth]="true"
              [placeholder]="MESSAGES.FIELD_LABEL_SCHEDULE"
              [options]="scheduleOptions$ | async"
              [control]="form.get(FORM_FIELDS.BELL_SCHEDULE_IDS)">
            </mg-form-select>
            <mg-form-error *ngIf="form.getError('overlappingDays')">
              {{ MESSAGES.ERROR_NO_OVERLAPPING_TERMS_SCHEDULE_ERROR }}
            </mg-form-error>
          </div>
        </fieldset>
      </div>
    </form>
  </main>
</ng-template>

<!-- Footer Content Templat -->
<ng-template #footerTemplate>
  <footer
    fxLayout="row"
    fxLayoutAlign="space-between center">
    <div>
      <mg-btn
        *ngIf="(isSisSynced$ | async) === false"
        [variant]="(isNew$ | async) === true ? 'text' : 'destructive'"
        (pressed)="confirmDelete()">
        {{ deleteLabel }}
      </mg-btn>
    </div>
    <div>
      <mg-btn
        attrType="submit"
        attrFormId="bs-terms-form"
        variant="filled"
        [disabled]="!canSubmit">
        {{ submitLabel }}
      </mg-btn>
    </div>
  </footer>
</ng-template>
