import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

import { onDeviceReady$ } from '@lib/cordova/device-ready';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppRuntime, AppRuntimeInterface } from '@shared/services/app-runtime';

import { BottomSheetEventType } from './bottom-sheet.types';

@Component({
  selector: 'mg-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSheetComponent implements OnInit, OnDestroy {
  // Host bindings

  @HostBinding('class.ios-bottom-safe-area') _enableSafeAreaPadding = false;

  // Children

  @ContentChild('actions', { read: TemplateRef })
  actions: TemplateRef<any>;

  // General Observables

  private _destroyedSubject = new ReplaySubject<void>(1);

  // Inputs

  @Input() title: string;
  @Input() isLoading: boolean;
  @Input() showBackBtn = false;
  @Input() showCloseBtn = true;
  @Input() fullHeight = false; // takes up full height instead of dynamic based on content

  // Outputs

  @Output() onBack: EventEmitter<void> = new EventEmitter(); // back button override
  @Output() onClose: EventEmitter<void> = new EventEmitter(); // close button override
  @Output() onEsc: EventEmitter<void> = new EventEmitter(); // esc button override

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(
    event: KeyboardEvent,
  ) {
    if (this.onEsc.observers.length > 0) {
      this.onEsc.emit();
    }
  }

  // Component Constructor

  constructor(
    private _bottomSheetRef: MatBottomSheetRef,
    private _cdr: ChangeDetectorRef,
    @Inject(AppRuntimeInterface) private _runtime: AppRuntime,
  ) {}

  // Lifecycle Hooks

  public ngOnInit(): void {
    onDeviceReady$.pipe(takeUntil(this._destroyedSubject)).subscribe(() => {
      if (this._runtime.isNativeApp('android')) {
        document.addEventListener(
          'backbutton',
          this._handleAndroidBackButtonOverride,
          false,
        );
      }
    });

    /**
     * iOS devices without a home button and running the native app, need
     * extra padding on the bottom of the device, because iOS renders a black
     * bar along the bottom that obstructs content.
     */
    this._enableSafeAreaPadding =
      this._runtime.isNativeApp('ios') &&
      !this._runtime.hasAttribute('homebutton');

    /** Trigger the host binding update for the safe area padding on iOS */
    this._cdr.detectChanges();
  }

  public ngOnDestroy(): void {
    document.removeEventListener(
      'backbutton',
      this._handleAndroidBackButtonOverride,
      false,
    );
  }

  get classes() {
    return {
      'has-actions': !!this.actions,
      'full-height': this.fullHeight,
    };
  }

  // Public Methods

  public back(): void {
    if (this.onBack.observers.length > 0) {
      this.onBack.emit();
    } else {
      this._bottomSheetRef.dismiss({
        type: BottomSheetEventType.BACK,
        data: {},
      });
    }
  }

  public close(): void {
    if (this.onClose.observers.length > 0) {
      this.onClose.emit();
    } else {
      this._bottomSheetRef.dismiss({
        type: BottomSheetEventType.CLOSE,
        data: {},
      });
    }
  }

  // Private Methods

  /**
   * for this component the back button in Android devices should go back or close the mobile sheet
   * insted of navigating back though the app. This only affects our Cordova apps so mobile web will
   * still navigate back
   */
  private _handleAndroidBackButtonOverride(event: Event): void {
    if (this.showBackBtn) {
      this.back();
    } else {
      this.close();
    }

    event.preventDefault();
  }
}
