import { CacheItemMethods } from '../cache/cache.types';

/**
 * Helper for keeping a collection of items in a cache
 */
export const createCacheCollection = <T = object>(
  creatorFn: (key) => CacheItemMethods<T>,
) => {
  const cache = new Map<string, CacheItemMethods<T>>();
  return {
    get: (key): CacheItemMethods<T> => {
      if (cache.has(key)) {
        return cache.get(key);
      }

      const item = creatorFn(key);
      cache.set(key, item);
      return item;
    },
  };
};

export type UpdateListCacheParams<Data> =
  | { action: 'create'; data: Data }
  | { action: 'update'; data: Data }
  | { action: 'delete'; data: number | string };

export const getUpdatedCacheCollection = <
  T extends { id: number | string } = any,
>(
  opts: UpdateListCacheParams<T>,
  existing: T[],
) => {
  switch (opts.action) {
    case 'create':
      existing.push(opts.data);
      break;
    case 'update':
      existing = existing.map(e =>
        e.id === opts.data.id ? { ...e, ...opts.data } : e,
      );
      break;
    case 'delete':
      existing = existing.filter(s => s.id !== opts.data);
      break;

    default:
      break;
  }

  return existing;
};
