import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { get } from 'lodash';
import { Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { MingaPermission } from 'minga/util';
import { PermissionsService } from 'src/app/permissions';

import { LayoutService } from '@modules/layout/services';

import { AppRuntime, AppRuntimeInterface } from '@shared/services/app-runtime';
import { MediaService } from '@shared/services/media';
import { xlsxExport } from '@shared/utils/xlsx-export';

import { MmDashboardTableComponent } from './components/mm-dashboard-table/mm-dashboard-table.component';
import {
  MM_DASHBOARD_COLUMN_PRESET_OPTIONS,
  MmDashboardColumnPresets,
  MmDashboardFilter,
  MmDashboardMessages,
  MmDashboardTableColumnToMessage,
  TOGGLE_COLUMNS_OPTIONS,
} from './constants';
import {
  MmDashboardFiltersService,
  MmDashboardService,
  MmDashboardTableService,
} from './services';
import { ToggleMenuData } from './types';

@Component({
  selector: 'mg-ma-dashboard',
  templateUrl: './mm-dashboard.component.html',
  styleUrls: ['./mm-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MmDashboardComponent implements OnInit, OnDestroy {
  /** Child Components */
  @ViewChild(MmDashboardTableComponent)
  dashTable: MmDashboardTableComponent;

  /** Constants */
  public readonly MESSAGES = MmDashboardMessages;
  public readonly FILTERS = MmDashboardFilter;
  public readonly PRESETS = MM_DASHBOARD_COLUMN_PRESET_OPTIONS;
  public readonly PRESET_TYPES = MmDashboardColumnPresets;

  /** General Observables */
  private _destroyed$ = new ReplaySubject<void>(1);
  public readonly media$: Observable<any>;

  public readonly toggleOptions$: Observable<ToggleMenuData>;

  /** Component Constructor */
  constructor(
    public media: MediaService,
    public mediaObserver: MediaObserver,
    public dashboardFilters: MmDashboardFiltersService,
    public dashboardService: MmDashboardService,
    public dashboardTable: MmDashboardTableService,
    public layout: LayoutService,
    private _permissions: PermissionsService,
    @Inject(AppRuntimeInterface) private _runtime: AppRuntime,
  ) {
    this.media$ = this.mediaObserver.asObservable().pipe(
      takeUntil(this._destroyed$),
      map(change => change[0].mqAlias),
      distinctUntilChanged(),
    );

    let toggleOptions: ToggleMenuData = [];
    if (!this._permissions.hasPermission(MingaPermission.SUPERADMIN)) {
      TOGGLE_COLUMNS_OPTIONS.forEach(option => {
        if (!option.sa) {
          option.options = option.options.filter(column => !column.sa);
          toggleOptions.push(option);
        }
      });
    } else {
      toggleOptions = TOGGLE_COLUMNS_OPTIONS;
    }
    this.toggleOptions$ = of(toggleOptions);
  }

  ngOnInit(): void {
    this.dashboardService.fetchAllData();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  getfilteredData() {
    if (this.dashTable) {
      return this.dashTable.getFilteredData();
    }
    return [];
  }

  getColumns() {
    if (this.dashTable) {
      return this.dashTable.getColumns();
    }
    return [];
  }

  public get canDownloadExport(): boolean {
    return !this._runtime.isNativeApp();
  }

  export() {
    const filterData = this.getfilteredData();
    const columns = this.getColumns();

    const data = filterData.map(minga =>
      columns.map(column => {
        return get(minga, column);
      }),
    );
    const headers = columns.map(
      column => MmDashboardTableColumnToMessage[column],
    );

    xlsxExport(headers, data, 'mingas.xlsx');
  }
}
