import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { isEmpty, xor } from 'lodash';

import { FiltersFormData } from '../types';

@Pipe({ name: 'checkChipVisibility' })
export class FiltersFormCheckChipVisibilityPipe implements PipeTransform {
  transform(
    { key, value }: KeyValue<string, any>,
    dataStructure: FiltersFormData,
    formState: Record<string, any>,
  ) {
    let field = dataStructure[key];
    if (!field) return null;
    if (typeof field === 'function') field = field(formState);
    if (!value || field.type === 'toggle-group') return null;
    if (Array.isArray(value)) {
      return !isEmpty(xor(value, field.default ?? []))
        ? { ...field, value }
        : null;
    } else {
      return value !== field?.default ? { ...field, value } : null;
    }
  }
}
