import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';

import { xor } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { WeekdayToggleMessages } from './weekday-toggle.constants';
import { Weekday } from './weekday-toggle.types';
import { getNameOfWeeksDaysObservable } from './weekday-toggle.utils';

@Component({
  selector: 'mg-weekday-toggle',
  templateUrl: './weekday-toggle.component.html',
  styleUrls: ['./weekday-toggle.component.scss'],
})
export class WeekdayToggleComponent implements OnDestroy {
  /** Constants */
  public readonly MESSAGES: typeof WeekdayToggleMessages =
    WeekdayToggleMessages;

  /** Options */
  public readonly options$: Observable<string[]>;
  private readonly _activeOptions$ = new BehaviorSubject<string[]>([]);
  public readonly activeOptions$ = this._activeOptions$.asObservable();

  /** Inputs */
  @Input() readonly: boolean;
  @Input()
  set initialValue(val: string[]) {
    this._activeOptions$.next(val);
  }
  @Input() invalidDays: Weekday[] = [];

  /** Events */
  @Output()
  public readonly valueChanged = new EventEmitter<string[]>();

  /** Component Constructor */
  constructor() {
    this.options$ = getNameOfWeeksDaysObservable();
  }

  ngOnDestroy(): void {
    this._activeOptions$.complete();
  }

  public async toggleOption(day: string): Promise<void> {
    if (this.readonly) return;
    const activeOptions = xor(this._activeOptions$.getValue(), [day]);
    this._activeOptions$.next(activeOptions);
    this.valueChanged.emit(activeOptions);
  }
}
