import { FormArray, FormGroup } from '@angular/forms';

import {
  ExplicitItemConfig,
  FormConfig,
  ItemConfig,
} from './crud-form-base.types';

export const markNestedFormGroupAsDirty = (form: FormGroup) => {
  Object.values(form.controls).forEach(control => {
    if (control instanceof FormGroup) {
      markNestedFormGroupAsDirty(control);
    } else if (control instanceof FormArray) {
      control.controls.forEach(control => {
        if (control instanceof FormGroup) {
          markNestedFormGroupAsDirty(control);
        }
      });
    } else {
      control.markAsTouched();
      control.updateValueAndValidity();
    }
  });
};

const isImplicit = <Data>(value: ItemConfig<Data>): boolean => {
  return Array.isArray(value);
};

const isNested = (value): boolean => {
  return !Array.isArray(value) && !value['key'];
};

/**
 * Sets form values from data object (form initialization)
 */
export const setForm =
  <FormFields extends PropertyKey, Data>(
    formConfig: FormConfig<FormFields, Data>,
  ) =>
  (data: Data, form: FormGroup): void => {
    const setItems = (config: FormConfig<FormFields, Data>) => {
      const formData = {};
      Object.entries(config).forEach(([key, value]) => {
        if (isNested(value)) {
          formData[key] = setItems(value as FormConfig<any, any>);
        } else {
          const implicit = isImplicit(value as ItemConfig<Data>);
          const defaultValue = implicit
            ? value[0]
            : (value as ExplicitItemConfig<Data>).formControl[0];

          if (implicit) {
            formData[key] = data[key] || defaultValue;
          } else {
            const itemConfig = value as ExplicitItemConfig<Data>;

            formData[key] = itemConfig.set
              ? itemConfig.set(data)
              : data[itemConfig.key] || defaultValue;
          }
        }
      });
      return formData;
    };

    const controls = setItems(formConfig);
    form.patchValue(controls);
    form.markAsPristine();
    form.markAsUntouched();
  };

/**
 * Goes through form data and maps to data object (form submission)
 */
export const getData =
  <FormFields extends PropertyKey, Data>(
    formConfig: FormConfig<FormFields, Data>,
  ) =>
  (data: Data, form: FormGroup): Data => {
    const getItems = (values, config) => {
      let itemsData: any = {};
      Object.entries(values)
        .filter(([key]) => config[key])
        .forEach(([key, value]) => {
          if (isNested(config[key])) {
            itemsData[key] = getItems(value, config[key]);
          } else {
            const itemConfig = config[key] as ExplicitItemConfig<Data>;

            const implicit = isImplicit(config[key]);

            if (implicit) {
              itemsData[key] = value;
            } else {
              const dataKey = itemConfig.key;
              itemsData[dataKey] = itemConfig.get
                ? itemConfig.get(formValues)
                : value;
            }
          }
        });

      return itemsData;
    };

    const formValues = form.value;

    const items = getItems(formValues, formConfig);

    const mapped = {
      ...data,
      ...items,
    };

    return mapped;
  };

/**
 * Initializes form control configuration
 */
export const createForm =
  <Config extends FormConfig<any, any>>(formConfig: Config) =>
  () => {
    const create = (config: Config) => {
      let data = {};
      Object.entries(config).forEach(([key, value]) => {
        if (isNested(value)) {
          data[key] = create(value as any);
        } else {
          const formControl = Array.isArray(value) ? value : value.formControl;
          data[key] = formControl;
        }
      });

      return data;
    };

    return create(formConfig);
  };
