import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { LayoutService } from '@modules/layout/services';

import { ManagerOverlayColorTheme } from '@shared/components/manager-overlay';

import { CheckinManagerMessages } from './checkin-manager.constants';
import { CheckinRoutesService } from './checkin-manager.routes.service';
import { CheckinManagerReportsService } from './components';

@Component({
  selector: 'mg-checkin-manager',
  templateUrl: './checkin-manager.component.html',
  styleUrls: ['./checkin-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CheckinManagerReportsService],
})
export class CheckinManagerComponent implements OnInit {
  /** Messages */
  MESSAGES: typeof CheckinManagerMessages = CheckinManagerMessages;

  /** Manager Overlay Color Theme */
  colorTheme: ManagerOverlayColorTheme =
    ManagerOverlayColorTheme.CHECKIN_MANAGER;

  constructor(
    private checkinRoutesService: CheckinRoutesService,
    public layout: LayoutService,
  ) {}

  /** Component Lifecycle: On Mount */
  ngOnInit(): void {}

  async onOverlayClose() {
    this.checkinRoutesService.returnFromCheckinRoute();
  }
}
